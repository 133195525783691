//document.addEventListener("DOMContentLoaded", function(event) {
    let getOrientation = () => {
        if (window.innerHeight > window.innerWidth) {
            return 'vertical';
        }
        return 'horizontal';
    }
    let video = document.querySelector('.js-index-video');
    const videoParameters = video ? JSON.parse(video.dataset.parameters) : null;
    let videoWebm = video ? video.querySelector('.js-index-video-webm') : null;
    let videoMp4 = video ? video.querySelector('.js-index-video-mp4') : null;
    let oldOrientation = '';
    function setOrientation() {
        let o = getOrientation();
        if (video && oldOrientation != o) {
            oldOrientation = o;
            if (o == 'vertical') {
                video.poster = videoParameters.poster_vertical;
                video.classList.add('-is-vertical')
                video.classList.remove('-is-horizontal')
            } else {
                video.poster = videoParameters.poster_horizontal;
                video.classList.remove('-is-vertical')
                video.classList.add('-is-horizontal')
            }
            let source = document.createElement('source');
            videoWebm.src = videoParameters['webm_' + o];
            videoMp4.src = videoParameters['mp4_' + o];
            video.load();

            setTimeout(() => {
                video.classList.add('-is-loaded');
                video.play();
            }, 100);

        }
    }
    window.addEventListener('resize', function(event) {
        debounce(setOrientation(), 500);
    });

    setOrientation();

    //let debounced = _.debounce(setOrientation, 100, { 'maxWait': 500 });
    /* $(window).on('resize', function() {
        debounced();
    }); */
//});
