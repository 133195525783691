if (document.querySelector('.js-service-item')) {
    document.body.classList.add('-is-catalog-index-page');
    let tabs = document.querySelectorAll('.js-service-tab');
    let services = document.querySelectorAll('.js-service-item');
    let wrapper = document.querySelector('.catalog-index');
    function showActiveItems() {
        let activeTabElement = document.querySelector('.js-service-tab.-is-active');
        let activeKey = activeTabElement.dataset.key;
        services.forEach(s => {
            if (s.dataset.key == activeKey) {
                s.classList.remove('d-none');
            } else {
                s.classList.add('d-none');
            }
        })

    }
    document.addEventListener('click', function(event) {
        let tab = event.target.closest('.js-service-tab');
        if (tab) {
            event.preventDefault();
            tabs.forEach(t => t.classList.remove('-is-active'));
            tab.classList.add('-is-active');
            showActiveItems();
            wrapper.classList.add('-is-show-services');
            wrapper.classList.remove('-is-show-list');
            return false;
        }
        let close = event.target.closest('.js-service-close');
        if (close) {
            event.preventDefault();
            if (wrapper.classList.contains('-is-show-services')) {
                wrapper.classList.remove('-is-show-services');
                wrapper.classList.add('-is-show-list');
            } else {
                window.location = '/';
            }

            return false;
        }
    });
    let hash = window.location.hash;

    if (hash) {

        wrapper.classList.add('-is-show-list');
        let isSet = false;
        tabs.forEach(t => {
            if ('#'+t.dataset.key != hash) {
                t.classList.remove('-is-active')
            } else {
                t.classList.add('-is-active')
                isSet = true;
                wrapper.classList.add('-is-show-services');
                wrapper.classList.remove('-is-show-list');
            }
        });
        if (!isSet) {
            tabs[0].classList.add('-is-active')
        }
    } else {
        wrapper.classList.remove('-is-show-services');
        wrapper.classList.add('-is-show-list');
    }

    showActiveItems();
}
