/**
* First we will load all of this project's JavaScript dependencies which
* includes Vue and other libraries. It is a great starting point when
* building robust, powerful web applications using Vue and Laravel.
*/
require('./bootstrap');
/* import $ from 'jquery';
window.$ = window.jQuery = $; */

import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui/ui/widgets/droppable.js';
//import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.ru.min.js';
//import './blocks/dropdown2.js';
window.Vue = require('vue');
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
//Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('personal-chat-component', require('./components/PersonalChatComponent.vue').default);
Vue.component('personal-chat2-component', require('./components/PersonalChat2Component.vue').default);
Vue.component('direct-chat-component', require('./components/DirectChatComponent.vue').default);
Vue.component('admin-chat-component', require('./components/AdminChatComponent.vue').default);
Vue.component('notify-bar',   require('./components/NotifyBar.vue').default);
Vue.component('messages-bar', require('./components/MessagesBar.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
let wr = document.getElementById('wr');
if (wr) {
    const app = new Vue({
        el: '#app',
    });
}


window.Masonry = require('masonry-layout')
document.addEventListener("DOMContentLoaded", function(event) {
    document.body.classList.remove('-is-loading');
    if (window.innerWidth <= 768) {
        if (!document.body.classList.contains('-is-front')) {
            document.body.classList.add('-is-hide-header');
        }
    }
})
require('./common');
require('./masterstl');
require('./orientation');
require('./town');
require('./search');
require('./sliders');
require('./latastRegistered');
require('./activity');
require('./services');
require('./catalog');
require('./map');
require('./pagination');
require('./order');
require('./profile');
require('./review');

