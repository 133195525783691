<template>
    <div class="direct_chat_messages">
        <div class="_chat">
            <div class="chat_box">
                <div class="chat_box_wrapper scroll">
                    <div class="">
                        <div v-for="item in messages">
                            <div class="_c">
                                <div><span :style="{ backgroundImage: `url('${item.avatar}')` }"></span></div>
                                <div v-html="item.msg"></div>
                                <div>{{item.date}}</div>
                            </div>
                        </div>
                        <p v-if="msg_loading" style="font-size: 11px;padding:15px;text-align:center;">Loading...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            'writeToId' : {
                type: Number
            },
            'myId' : {
                type: Number
            },
            'myAvatar' : {
                type: String
            }
        },

        data() {
            return {
                messages: [],
                txtMsg: "",
                msg_loading: false,
                loaded: false,
                chat_id: false,
                login: null,
                user_id: null,
            }
        },

        mounted()
        {
            axios.post('/messages/init_direct_chat', {
                        writeToId : this.writeToId ,
                        myId : this.myId ,
                    })
                    .then((response) => {
                        if (response.status === 200)
                        {
                            this.loaded = true;

                            if (response.data.login !== undefined && response.data.login !== null) {
                                this.login = response.data.login;
                            }
                            if (response.data.user_id !== undefined && response.data.user_id !== null) {
                                this.user_id = response.data.user_id;
                            }

                            this.messages = response.data.messages;

                            this.changeChannel(response.data.chat_id);
                            this.scrollBottom();
                        }
                    });

            this.scrollBottom();
        },

        methods : {
            activeClass()
            {
                if (this.chat_id == parseInt(e.currentTarget.getAttribute('data-chat-id'))) {
                    return 'active';
                } else {
                    return '';
                }
            },
            /**
             * openChannel
             */
            changeChannel(new_chat_id)
            {
                if (this.chat_id != null) {
                    window.Echo.leave('chat.' + this.chat_id);
                }

                this.chat_id = new_chat_id;

                window.Echo.private('chat.' + this.chat_id)
                        .listen('ChatMessage', (query) =>
                        {
                            // check on already exists
                            for (let i = 0; i < this.messages.length; i++){
                                if (parseInt(query.id) === parseInt(this.messages[i].id)) {
                                    return;
                                }
                            }
                            this.messages.push(query);
                            this.$forceUpdate();
                            this.scrollBottom();
                        });
            },
            /**
             * scrollBottom
             */
            scrollBottom() {
                setTimeout(()=>{
                    let container = this.$el.querySelector(".chat_box_wrapper");
                    container.scrollTop = container.scrollHeight;
                }, 250);
            },
        }
    }
</script>
