var bookOrder2 = {

    selected_business_id : null,

    initTimes: function()
    {
        $("#bookClientModal ._dates ._time").off("click").on("click", function()
        {
            $('#bookClientModal .modal-body .service_add').hide();
            $('#bookClientModal .modal-body ._datepicker').hide();
            $('#bookClientModal .modal-body ._dates').hide();
            $('#bookClientModal .modal-body ._choose_date_block').hide();

            $('#bookClientModal .modal-body .services1').show();
            $('#bookClientModal .modal-body ._confirm2_block').show();
            $('#bookClientModal .modal-body ._confirm_block').show();
            $('#bookClientModal .modal-body ._confirm_block ._date_time, #bookClientModal .modal-body ._confirm2_block ._date_time')
                .text( $(this).data('txt') )
                .data('time', $(this).data('time') );

            $("#bookClientModal ._cancel_book_btn").off("click").on("click", function(){
                $('#bookClientModal .modal-body ._confirm_block').hide();
                $('#bookClientModal .modal-body ._confirm2_block').hide();

                $('#bookClientModal .modal-body .service_add').show();
                $('#bookClientModal .modal-body ._datepicker').hide();
                $('#bookClientModal .modal-body ._dates').show();
                $('#bookClientModal .modal-body ._choose_date_block').show();
            });

            $("#bookClientModal ._show_reg_bonus_pre_window").off("click").on("click", function() {
                $("#bookClientModal .services1").hide();
                $("#bookClientModal .services2").hide();
                $("#bookClientModal ._confirm_block > *").hide();
                $("#bookClientModal ._confirm2_block").hide();
                $("#bookClientModal ._reg_bonus_pre_window").show();
                $("#bookClientModal #bookClientModalTitle").html('Заказ услуги');
            });

            $("#bookClientModal ._confirm_btn").off("click").on("click", function() {

                let _ids = [];
                let _time = $('#bookClientModal .modal-body ._confirm2_block ._date_time').data('time');

                $("#bookClientModal .modal-body ._row ._check input[type='checkbox']").each(function(){
                    if ($(this).prop("checked") === true) {
                        _ids.push($(this).val());
                    }
                });

                let book_name   = $('#bookClientModal input[name="book_name"]').val();
                let book_phone  = $('#bookClientModal input[name="book_phone"]').val();
                let book_notice = $('#bookClientModal textarea[name="book_notice"]').val();

                if ($('#bookClientModal input[name="book_phone"]').length > 0 && book_phone.length < 10) {
                    $('#bookClientModal input[name="book_phone"]')[0].setCustomValidity("Требуется указать телефон");
                    $('#bookClientModal input[name="book_phone"]')[0].reportValidity();
                    return false;
                }
                if ($('#bookClientModal input[name="book_name"]').length > 0 && book_name.length <= 2) {
                    $('#bookClientModal input[name="book_name"]')[0].setCustomValidity("Укажите ваше имя");
                    $('#bookClientModal input[name="book_name"]')[0].reportValidity();
                    return false;
                }

                $('#bookClientModal .modal-body').html('<div class="_loader"><img src="/images/loader.svg" /> <span>Загрузка...</span></div>');

                let _logErr = 'ERRCODE:#2||' + _time + '||' + _ids.join(',') + '||' + $(".profile2-page").data('id');

                $.ajax({
                    url: '/business/set_book',
                    data: {
                        'business_id'  : $(".profile2-page").data('id'),
                        'promotion_id' : $('#bookClientModal').data('promotion-id'),
                        '_ids'         : _ids.join(','),
                        '_time'        : _time,

                        'book_name'    : book_name,
                        'book_phone'   : book_phone,
                        'book_notice'  : book_notice,
                    },
                    dataType: 'json',
                    type: 'post',
                    error: function () {
                        $('#bookClientModal .modal-body').html('Ошибка при загрузке... ' + _logErr);
                    },
                    success: function (response) {
                        $('#bookClientModal .modal-body').html(response.html);

                        if (response.success_book !== undefined && response.success_book === 1) {
                            $('#bookClientModal').addClass('book_modal_parent_no_header');
                        }
                    }
                });


            });
        });
    }

};

function __business_resize()
{

}
function __business_scroll()
{
    if ($(window).width() <= 450)
    {
        __business_resize();
    }
}

$().ready(function()
{
    $(window).on("resize", function(){
        __business_resize();
    });
    $(window).on("scroll", function(){
        __business_scroll();
    });
    __business_scroll();
    __business_resize();

    // .m2-services .__book
    $(".profile2-page .__book").on("click", function()
    {
        $('#bookClientModal').removeClass('book_modal_parent_no_header');

        if ($(window).width() <= 768)
        {
            $("#bookClientModal #bookClientModalTitle").html('Заказать услугу<br />или консультацию');
            $("#bookClientModal .modal-content").css({
                'height' : $(window).height() + 'px'
            });
            document.body.classList.add('-is-book-open');
        }
        else {
            $("#bookClientModal #bookClientModalTitle").html('Заказать услугу или консультацию');
        }

        $('#bookClientModal .modal-body').html('<div class="_loader"><img src="/images/loader.svg" /> <span>Загрузка...</span></div>');

        $('#bookClientModal')
                            .data('id', $(this).data('id'))
                            .data('promotion-id', $(this).data('promotion-id'))
                            .data('business-id', $(".profile2-page").data('id'))
                            .modal('show');
    });
    $('#bookClientModal').on('hidden.bs.modal', function() {
        document.body.classList.remove('-is-book-open');
    });
    // #bookClientModal
    $('#bookClientModal').on('shown.bs.modal', function()
    {
        let _logErr = 'ERRCODE:#3||' + $(this).data('id') + '||' + $(".profile2-page").data('id');

        $.ajax({
            url: '/business/get_book_info',
            data: {
                'service_id'   : $(this).data('id'),
                'business_id'  : $(this).data('business-id'),
                'promotion_id' : $(this).data('promotion-id')
            },
            dataType: 'json',
            type: 'post',
            error: function(){
                $('#bookClientModal .modal-body').html('Ошибка при загрузке... ' + _logErr);
            },
            success: function (response){
                $('#bookClientModal .modal-body').html(response.html);

                bookOrder2.initTimes();

                $('#bookClientModal .modal-body .services2').hide();
                $('#bookClientModal .modal-body ._datepicker').hide();

                $('#bookClientModal .modal-body .service_add').on("click", function(){
                    $('#bookClientModal .modal-body .services2').slideDown();
                    $('#bookClientModal .modal-body ._dates').slideUp();
                    $('#bookClientModal .modal-body ._choose_date_block').hide();
                    $('#bookClientModal .modal-body .service_add').hide();

                    $('#bookClientModal ._row ._check .custom-checkbox').show();

                    $('#bookClientModal ._apply_services_btn').off("click").on("click", function() {
                        $("#bookClientModal .modal-body ._row ._check input[type='checkbox']").each(function(){

                            let $_elem = $(this).parents("._row");

                            if ($(this).prop("checked") === true) {
                                $('#bookClientModal .modal-body .services1').append($_elem[0].outerHTML);
                            } else {
                                $('#bookClientModal .modal-body .services2').prepend($_elem[0].outerHTML);
                            }

                            $_elem.remove();
                        });

                        $('#bookClientModal .modal-body .services1 input[type="checkbox"]').prop("checked", true);
                        $('#bookClientModal .modal-body .services2 input[type="checkbox"]').prop("checked", false);

                        // hide all
                        $('#bookClientModal .modal-body .services1').show();
                        $('#bookClientModal .modal-body .services2').hide();
                        $('#bookClientModal .modal-body ._dates').show();
                        $('#bookClientModal .modal-body ._choose_date_block').show();
                        $('#bookClientModal ._row ._check .custom-checkbox').hide();
                        $('#bookClientModal .modal-body .service_add').show();
                    });
                });

                $('#bookClientModal .modal-body ._choose_date_btn').on("click", function(){
                    $('#bookClientModal .modal-body ._datepicker').slideDown();
                    $('#bookClientModal .modal-body ._dates').slideUp();
                    $('#bookClientModal .modal-body .services2').slideUp();
                    $('#bookClientModal .modal-body .services1').slideUp();
                    $('#bookClientModal .modal-body .service_add').hide();
                    $('#bookClientModal .modal-body ._choose_date_block').hide();
                });

                /**
                 * book-date-picker
                 */
                $("#book-date-picker").datepicker({
                    inline: true,
                    weekStart: 1,
                    language: 'ru',
                    startDate: "today"
                }).on('changeDate', function (ev) {
                    let _date = $("#book-date-picker").data('datepicker').getFormattedDate('yyyy-mm-dd');
                    $("#bookClientModal ._dates").html('<div class="_loader"><img src="/images/loader.svg" /> <span>Загрузка...</span></div>');

                    let _logErr = 'ERRCODE:#1||' + _date + '||' + $(".profile2-page").data('id');

                    $.ajax({
                        url: '/business/get_book_dates',
                        data: {
                            'selected_date' : _date,
                            'business_id'   : $(".profile2-page").data('id'),
                            'promotion_id'  : $('#bookClientModal').data('promotion-id')
                        },
                        dataType: 'json',
                        type: 'post',
                        error: function(){
                            $("#bookClientModal ._dates").html('Ошибка при загрузке... ' + _logErr);
                        },
                        success: function (response){
                            $("#bookClientModal ._dates").remove();
                            $("#bookClientModal ._datepicker").before(response.html);

                            bookOrder2.initTimes();
                        }
                    });

                    // hide all
                    $('#bookClientModal .modal-body .services1').show();
                    $('#bookClientModal .modal-body .service_add').show();
                    $('#bookClientModal .modal-body ._datepicker').hide();
                    $('#bookClientModal .modal-body ._dates').show();
                    $('#bookClientModal .modal-body ._choose_date_block').show();
                });
            }
        });

    });

    /**
     * open_direct_chat
     */
    if ($("#open_direct_chat").length > 0) {
        $("._send_msg_block > a").click();
    }

});
