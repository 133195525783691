document.addEventListener('click', function(event) {
    let link = event.target.closest('.js-paginate');
    if (link) {
        event.preventDefault();
        document.body.classList.add('-is-loading');
        let wrapper = link.closest('.js-paginate-wrapper');

        axios.get(link.href)
        .then((response) => {
            wrapper.innerHTML = response.data.html;
            let y = wrapper.getBoundingClientRect().top + window.pageYOffset -85
            window.scrollTo({top: y, behavior: 'smooth'});
            document.body.classList.remove('-is-loading');
        })
        .catch(function(error) {
            console.log(error);
        });
    }
})
