window.masterMap = {

    mapContainer     : null,
    myMap             : null,
    currentPosition   : undefined,
    currentGeo        : undefined,

    printed_ids : [],

    initBusinessPage : function()
    {
        masterMap.myMap = new ymaps.Map("map", {
            center: [ masterMap.mapContainer.dataset.lat, masterMap.mapContainer.dataset.lng ],
            zoom: masterMap.mapContainer.dataset.zoom,
            controls: ['zoomControl', 'fullscreenControl']
        });

        masterMap.myMap.geoObjects.add(new ymaps.Placemark([ masterMap.mapContainer.dataset.lat, masterMap.mapContainer.dataset.lng ], {
            // balloonContent: _html
        }, {
            preset: 'islands#dotIcon',
            iconColor: '#000000'
        }))
    },

    initWindowMap : function(__id_container, lat, lng)
    {
        masterMap.myMap = new ymaps.Map(__id_container, {
            center: [ lat, lng ],
            zoom: 13,
            controls: ['zoomControl', 'fullscreenControl']
        });

        masterMap.myMap.geoObjects.add(new ymaps.Placemark([ lat, lng ], {
            // balloonContent: _html
        }, {
            preset: 'islands#dotIcon',
            iconColor: '#000000'
        }))
    },

    initCatalog : function()
    {
        let locationVal = $(".__location_id").val();

        let _lat  = masterMap.mapContainer.dataset.lat;
        let _lng  = masterMap.mapContainer.dataset.lng;
        let _zoom = masterMap.mapContainer.dataset.zoom;
        // For Firefox
        if (masterSTL._current_lat == null && masterSTL._current_lng == null) {
            if (masterMap.mapContainer.dataset.detected !== undefined && parseInt(masterMap.mapContainer.dataset.detected) === 1) {
                masterSTL._current_lat = _lat;
                masterSTL._current_lng = _lng;
            }
        }

        if (
            ( locationVal === 'all' || locationVal === 'current' )
            && masterSTL._current_lat !== null && masterSTL._current_lng !== null
        ) {
            _zoom = 14;
            _lat  = masterSTL._current_lat;
            _lng  = masterSTL._current_lng;
        }

        masterMap.myMap = new ymaps.Map("map", {
            center   : [ _lat, _lng ],
            zoom     : _zoom,
            controls : ['zoomControl', 'fullscreenControl']
        });

        // Add user point
        if (
            ( locationVal === 'all' || locationVal === 'current' )
            && masterSTL._current_lat !== null && masterSTL._current_lng !== null
        )
        {
            masterMap.myMap.geoObjects.add(new ymaps.Placemark([masterSTL._current_lat, masterSTL._current_lng], {
                balloonContent: 'Ваше местоположение'
            }, {
                preset: 'islands#circleIcon',
                iconColor: '#3caa3c'
            }));

            masterMap.loadPoints(masterSTL._current_lat, masterSTL._current_lng);
        }
        else
        {
            masterMap.loadPoints(_lat, _lng);
        }

        // Map Movements
        /* masterMap.myMap.events.add('actionend', function (e) {
            let __cords = masterMap.myMap.getCenter();
            masterMap.loadPoints(__cords[0], __cords[1]);
        }); */

        // Find current position || async
        if (!+window.lat || !+window.lng) {
            var location = ymaps.geolocation.get();
            location.then(
                function (result) {
                    masterMap.currentGeo = result.geoObjects;
                    if (result.geoObjects.position !== undefined && result.geoObjects.position.length === 2)
                    {
                        masterMap.currentPosition = result.geoObjects.position;

                        masterSTL._current_lat = result.geoObjects.position[0];
                        masterSTL._current_lng = result.geoObjects.position[1];

                        $.ajax({
                            url: '/location/detected2',
                            data: {
                                'lat' : masterSTL._current_lat,
                                'lng' : masterSTL._current_lng
                            },
                            dataType: 'json',
                            type: 'post',
                            error: function(){},
                            success: function (response){
                                if (response.redirect) {
                                    window.location = response.redirect;
                                }
                            }
                        });

                        if (catalog2 !== undefined && catalog2.locationDetected2 !== undefined) {
                            catalog2.locationDetected2();
                        }
                    }
                },
                function (err) {
                }
            );
        }
    },

    loadPoints : function()
    {
        const form = document.querySelector('#catalogSearchForm');
        const mapWrapper = document.getElementById('map');
        if (form) {
            const formData = new FormData(form);

            const url = mapWrapper.dataset.url;
            let params = {map: true}
            for (var pair of formData.entries()) {
                params[pair[0]] = pair[1];
            }
            axios.get(url,{ params })
                .then(function (response) {
                    if (response.data.status == 'success') {
                        if (response.data.lat && response.data.lng) {
                            masterMap.myMap.setCenter([response.data.lat, response.data.lng]);
                        }
                        masterMap.renderPoints(response.data.items);

                    }
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
        }

    },

    renderPoints : function(items)
    {
        if (masterMap.myMap === null) {
            return false;
        }
        masterMap.myMap.geoObjects.removeAll();
        $.each(items, function(k,v)
        {
            /* let isExist = false;
            for (let i = 0; i < masterMap.printed_ids.length; i++) {
                if (parseInt(v.id) === parseInt(masterMap.printed_ids[i].id)) {
                    isExist = true;
                }
            }

            if (isExist) {
                return;
            }

            masterMap.printed_ids.push(v.id); */

            let _html = '<div class="ya-baloon">';
                _html += '<div>';
                    if (v.img !== undefined) {
                        _html += '<img width="150" src="'+ ( v.img === undefined || v.img === null ? '/images/no.svg' : v.img ) +'" />';
                    }
                _html += '</div>';
                _html += '<div>';
                    _html += '<div class="_head">'+v.name+'</div>';
                    _html += '<p>'+ v.about +'</p>';
                    _html += '<div class="text-center"><a href="'+ v.url +'" class="btn btn-style">Открыть</a></div>';
                _html += '</div>';
            _html += '</div>';

            masterMap.myMap.geoObjects.add(new ymaps.Placemark([v.lat, v.lng], {
                balloonContent: _html
            }, {
                preset: 'islands#dotIcon',
                iconColor: '#1e90ff'
            }));

        });
    }

};

$().ready(function(){

    /* if ($(".catalog2-map").length > 0)
    {
        $("#map").css({
            'height' : $(".catalog2-map ._map").height()+'px'
        });

        masterMap.mapContainer = $("#map");
        ymaps.ready(masterMap.initCatalog);
    }
    else if ($(".business_map").length > 0)
    {
        masterMap.mapContainer = $("#map");
        ymaps.ready(masterMap.initBusinessPage);
    }
    else if ($(".sample_map").length > 0)
    {
        masterMap.mapContainer = $("#map");
    } */

});
document.addEventListener("DOMContentLoaded", function(event) {
    let mapElement = document.getElementById('map');
    if (mapElement) {
        masterMap.mapContainer = mapElement;
        if (mapElement.dataset.type == 'business') {
            ymaps.ready(masterMap.initBusinessPage);
        } else {
            ymaps.ready(masterMap.initCatalog);
        }
    }
})
