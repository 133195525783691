<template>
    <div class="_bell menu-top-bell" v-bind:class="{ _bell_active : not_read_count }">
        <a href="javascript:" @mouseover="mouseOver" @mouseleave="mouseLeave" @click="envelopeClick">
            <span v-if="not_read_count">{{ not_read_count }}</span><img src="/images/top/bell.svg" alt="" />
        </a>

        <div v-show="hover" class="_notify_bar_message" style="display: none;" @mouseover="mouseOver" @mouseleave="mouseLeave">
            <div class="_head"><div>Уведомления:</div><div><img src="/images/close.png" v-on:click="hide_popup" /></div></div>
            <div class="_items">
                <div class="_item" v-for="item in messages" v-bind:class="{ not_read: item.not_read }">
                    <div>
                        <a v-if="isPremium && item.from_id > 0" v-bind:href="'/to_user_business/'+item.from_id+'.html'"><i class="_img" v-bind:style="'background-image: url('+item.from_img+')'"></i></a>
                        <a v-if="isPremium && item.from_id === 0 && item.is_subscriber === 1" href="/feed?subscribers=1"><i class="_img" v-bind:style="'background-image: url('+item.from_img+')'"></i></a>
                        <a v-if="isPremium && item.from_id === 0 && item.is_subscriber === 0" href="javascript:"><i class="_img _mc" v-bind:style="'background-image: url(/images/chat_mc.svg?v=3)'"></i></a>
                        <a v-if="!isPremium && item.from_id > 0" v-bind:href="'/how-works.html'"><i class="_img" v-bind:style="'background-image: url('+item.from_img+')'"></i></a>
                        <a v-if="!isPremium && item.from_id === 0 && item.is_subscriber === 0" v-bind:href="'/how-works.html'"><i class="_img _mc" v-bind:style="'background-image: url(/images/chat_mc.svg?v=3)'"></i></a>
                        <a v-if="!isPremium && item.from_id === 0 && item.is_subscriber === 1" v-bind:href="'/feed?subscribers=1'"><i class="_img" v-bind:style="'background-image: url('+item.from_img+')'"></i></a>
                    </div>
                    <div @click="redirectToUser">
                        <div v-if="isPremium" v-html="item.txt"></div>
                        <div v-if="!isPremium" class="_hidden">Нажмите на Сообщение<div class="_line2">(требуется подписка)</div></div>
                        <div>{{ item.date }}</div>
                    </div>
                </div>
            </div>
            <div v-if="!isPremium" class="_no_premium_msg">Своевременно прочитанные уведомления от клиентов и системы платформы, исключают риск потерять клиента!</div>
            <p v-if="!messages.length">У вас нет новых уведомлений.</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'userId',
            'isPremium',
            'isBlockedClick',
        ],
        data() {
            return {
                messages: [],
                count: 0,
                not_read_count: 0,
                hover: false,
                opened: false,
            }
        },

        mounted() {
            window.Echo.private('notify_bar.' + this.userId)
                        .listen('NotifyBarMessage', (query) =>
                        {
                            this.messages.unshift(query.msg);
                            this.opened = false;
                            this.not_read_count++;
                            this.$forceUpdate();
                        });
            this.load();
        },
        methods : {
            hide_popup() {
                this.hover = false;
                this.opened = false;
                document.body.classList.remove("stop-scrolling");
            },
            load() {
                axios
                    .post('/messages/notify_bar', {
                        load_notify_bar : 1
                    })
                    .then((response) => {
                        if (response.status === 200)
                        {
                            this.count          = response.data.items.length;
                            this.messages       = response.data.items;
                            this.not_read_count = response.data.not_read_count;
                        }
                    });
            },
            mouseOver: function()
            {
                if (this.isBlockedClick) {
                    return;
                }

                this.hover = true;

                if (this.opened == false)
                {
                    // Без подписки не снимаем флаг прочитанности
                    if (!this.isPremium) {
                        return;
                    }

                    let ids = [];

                    for (let i = 0; i < this.messages.length; i++) {
                        if (this.messages[i] !== undefined && this.messages[i] !== null) {
                           ids.push( this.messages[i].id );
                        }
                    }

                    setTimeout(() => {

                        if (ids.length > 0)
                        {
                            axios
                                .post('/messages/notify_bar', {
                                    read_notify_bar : ids
                                })
                                .then((response) => {
                                    if (response.status === 200)
                                    {
                                        for (let i = 0; i < this.messages.length; i++) {
                                            if (this.messages[i] !== undefined && this.messages[i] !== null) {
                                                this.messages[i].not_read = 0;
                                                if (this.not_read_count > 0) {
                                                    this.not_read_count--;
                                                }
                                            }
                                        }
                                    }
                                });
                        }

                    }, 1000);

                    /*
                     * Если смартфон, расширяем до конца
                     */
                    if (window.innerWidth <= 500)
                    {
                        // Внимание! У на макете 2 шт. _notify_bar_message
                        let elems = document.querySelectorAll('.top-mobile-bars ._notify_bar_message');
                        let index = 0, length = elems.length;
                        for ( ; index < length; index++) {
                            elems[index].style.height = (window.innerHeight+10) + 'px';
                            elems[index].querySelector('._items').style.height = (window.innerHeight - elems[index].querySelector('._head').offsetHeight -50 ) + 'px';
                            elems[index].querySelector('._items').style.maxHeight = 'none';
                        }

                        document.body.classList.add("stop-scrolling");
                    }
                }

                this.opened = true;
            },
            mouseLeave: function(){
                this.hover = false;
            },
            envelopeClick : function() {
                if (this.isBlockedClick) {
                    masterSTL.restrictedHDModal();
                    return;
                }
            },

            redirectToUser: function(e){
                if (!this.isPremium) {
                    location.href = '/how-works.html';
                }
            }
        }
    }
</script>
