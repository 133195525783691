
document.addEventListener("DOMContentLoaded", function(event) {
    let hash = window.location.hash;
    if (hash && hash.indexOf('reviews')) {
        //$('#m2-tab-head-review').click();
        setTimeout(() => {
            let reviewTab = document.getElementById('m2-tab-head-review');
            reviewTab.click();
            let headerHeight = window.innerHeight > 768 ? 70 : 107;
            $('html,body').animate({scrollTop: $(hash).offset().top - headerHeight});

        }, 200);
    }
    if (window.innerWidth <= 768) {
        let mobileInput = document.querySelector('.profile2-page__header .js-search-input');
        if (mobileInput) {
            mobileInput.disabled = 'disabled';
        }
    }
});
