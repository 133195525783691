document.addEventListener("DOMContentLoaded", function(event) {
    let count = window.innerWidth >= 768 ? 21 : 12;
    let page = 0;
    let wrapper = document.querySelector('.js-activity-wrapper');
    let msnry = null;
    if (wrapper) {
        function loadActivity() {
            axios.get('/ajax/activity', { params: {count, page} })
                    .then((response) => {
                        if (response.data.count) {
                            let $items = $(response.data.html);
                            $(wrapper).append($items)
                            setGrid($items);
                        } else {
                            $('.js-load-activity').addClass('d-none');
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
        }
        document.addEventListener('click', function(event) {
            let btn = event.target.closest('.js-load-activity');
            if (btn) {
                page = +btn.dataset.page;
                loadActivity();
                btn.dataset.page = page + 1;
            }
        });
        function setGrid($items) {
            if (window.innerWidth > 565) {
                if (!msnry) {
                    setTimeout(() => {
                        msnry = new Masonry(wrapper, { "itemSelector": ".recent-act__item", "gutter": 26 });
                        msnry.layout();
                    }, 300);
                } else {
                    setTimeout(() => {
                        msnry.appended( $items )
                        msnry.layout();
                    }, 500);
                }

            }
        }
        loadActivity();
    }
});
