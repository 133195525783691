<template>
    <div class="_messages menu-top-message" v-bind:class="{ _messages_active: not_read_count }">
        <a href="javascript:" v-bind:class="{ isPremium: '_is_premium' }" @mouseover="mouseOver" @mouseleave="mouseLeave" @click="envelopeClick">
            <span v-if="not_read_count">{{ not_read_count }}</span><img src="/images/top/message.svg" alt="" />
        </a>

        <div v-show="hover" class="_chat_bar_message" style="display: none;" @mouseover="mouseOver" @mouseleave="mouseLeave">
            <div class="_h"><img src="/images/close.png" v-on:click="hide_popup" />Сообщения:</div>
            <div class="_items">
                <div class="_item" v-for="item in messages" v-bind:class="{ not_read: item.not_read }" @click="redirectToUser" :href="'/messages?write_to_id=' +item.user_id">
                    <div>
                        <i class="_img" v-bind:style="'background-image: url('+item.from_img+')'"></i>
                    </div>
                    <div>
                        <div><i>{{ item.name }}</i><i>{{ item.date }}</i></div>
                        <div v-if="isPremium" v-html="item.txt"></div>
                        <div v-if="!isPremium" class="_no_premium_msg_line">Нажмите на Сообщение<div class="_line2">(требуется подписка)</div></div>
                    </div>
                </div>

                <div class="_item not_read __temp_message" v-if="showTempMessage" data-toggle="modal" data-target="#tempMessageModal">
                    <div>
                        <i class="_img" v-bind:style="'background-image: url(/images/temp_avatars/1.jpg)'"></i>
                    </div>
                    <div>
                        <div><i>Ольга</i><i></i></div>
                        <div>К вам можно записаться?</div>
                    </div>
                </div>
            </div>
            <div v-if="!isPremium" class="_no_premium_msg">Вовремя отвеченные вопросы по бронированию или услугам мастера, могут принести в вашу копилку еще одного клиента.</div>
            <p v-if="!messages.length">У вас нет новых сообщений.</p>
            <div class="_all_notifications"><a href="/messages">Все сообщения</a></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'userId',
            'isPremium',
            'showTempMessage',
            'isBlockedClick',
            'tempTime',
            'firstShow',
            'neverPremium',
        ],
        data() {
            return {
                messages: [],
                count: 0,
                not_read_count: 0,
                hover: false,
                opened: false,
            }
        },

        mounted() {
            window.Echo.private('chat_bar.' + this.userId)
                        .listen('ChatBarMessage', (query) =>
                        {
                            this.load();
                            this.$forceUpdate();
                        });
            this.load();
        },
        methods : {
            hide_popup() {
                this.hover = false;
                this.opened = false;
            },
            load() {
                axios
                    .post('/messages/message_bar', {
                        load_notify_bar : 1
                    })
                    .then((response) => {
                        if (response.status === 200)
                        {
                            this.count          = response.data.items.length;
                            this.messages       = response.data.items;
                            this.not_read_count = response.data.not_read_count;

                            // Делаем +1, чтобы показывалось временное сообщение
                            if (this.showTempMessage) {
                                this.count++;
                                this.not_read_count++;
                            }

                            // можно уже чтобы окно сообщения было закрыто горел только зелёный кружок и только при нажатии на кружок сообщение открывалось
                            /*if (this.firstShow) {
                                this.hover = true;
                                this.opened = true;

                                axios.post('/messages/message_bar', {
                                    temp_message_first_show : 1
                                })
                                .then((response) => { });
                            }*/
                        }
                    });
            },
            mouseOver: function() {
                /*if (!this.isPremium) {
                    this.hover = true;
                    this.opened = true;
                }*/
            },
            mouseLeave: function(){
                this.hover = false;
            },
            envelopeClick : function() {

                if (this.isBlockedClick) {
                    masterSTL.restrictedHDModal();
                    return;
                }

                fs2.loadingV2();

                /*if (this.neverPremium === true) {
                    location.href = '/how-works.html';
                    return;
                }*/

                // Редирект происходит на how-works.html в контроллере
                location.href = '/messages';

                /*console.log('envelopeClick');
                if (this.isPremium) {
                    fs2.loadingV2();
                    location.href = '/messages';
                }*/
            },

            redirectToUser: function(e){
                if (this.isPremium) {
                    location.href = e.currentTarget.getAttribute('href');
                } else {
                    location.href = '/how-works.html';
                }
            }
        }
    }
</script>
