window.m2Reviews = {

    $modal : null,
    $dark : null,

    checkDOM : function()
    {
        this.$modal = $("body").find("#m2ReviewWindow");
        if (this.$modal.length === 0)
        {
            $("body").append(
                '<div id="m2ReviewWindow"></div>' +
                '<div id="m2ReviewWindowDark"></div>');

            this.$modal = $("body").find("#m2ReviewWindow");
            this.$dark  = $("body").find("#m2ReviewWindowDark");
        }
    },

    clickByReviewBtn : function() {
        $("#m2-tab-head-review").click();
        $(".m2-reviews .write-review-btn").click();
    },

    showAddForm : function(bid, name, avatar)
    {
        document.body.classList.add('-is-review-open');
        this.$modal.html('<div class="m2-add-review-loading"></div>' +
            '<div class="m2-add-review-content">' +
                '<div class="_top">' +
                    '<div></div>' +
                    '<b>Оцените исполнителя</b>' +
                    '<a href="javascript:" onclick="m2Reviews.close()"><img src="/images/m2/close.svg" /></a>' +
                '</div>' +

                '<div class="_avatar_block">' +
                    '<span style="background-image: url(\''+ avatar +'\')"></span>' +
                    '<i>' + name + '</i>' +
                '</div>' +

                '<div class="_stars">' +
                    '<span></span>' +
                    '<span></span>' +
                    '<span></span>' +
                    '<span></span>' +
                    '<span></span>' +
                '</div>' +

                '<div class="_hidden_block"><form action="" method="post" id="form_review_add" enctype="multipart/form-data">' +
                    '<input type="hidden" name="add_review" value="1" />' +
                    '<input type="hidden" name="stars" value="" />' +
                    '<input type="hidden" name="features" value="" />' +

                    '<div class="_features">' +
                        '<b>Что особенно понравилось?</b>' +
                        '<div class="_features_content"></div>' +
                    '</div>' +

                    '<div class="_fields">' +
                        '<b>Комментарий</b>' +
                        '<div>' +
                            '<textarea name="your_comment" placeholder="Какие у вас впечатления от работы с исполнителем?"></textarea>' +
                        '</div>' +
                        '<div>' +
                            '<input type="text" name="your_name" placeholder="Ваше имя" value="'+ ( $(".profile2-page").data('auth-name') !== undefined ? $(".profile2-page").data('auth-name') : '' ) +'" />' +
                        '</div>' +
                    '</div>' +

                    '<div class="_attach_button">' +
                        '<input type="file" id="file-selector" name="files[]" multiple accept=".jpg, .jpeg, .png" />' +
                        '<a href="javascript:"><img src="/images/m2/attach.svg" width="17" /> <span>Прикрепить фотографии результата</span></a>' +
                        '<div class="_preview"></div>' +
                    '</div>' +

                    '<div class="_send_button">' +
                        '<div class="_error"></div>' +
                        '<a href="javascript:">Отправить</a>' +
                    '</div>' +
                '</form></div>' +

            '</div>');

        $("form#form_review_add").submit(function(e) {
            e.preventDefault();
            let formData = new FormData(this);

            $(".m2-add-review-loading").show().css({
                'background' : 'rgba(0,0,0,0.2)',
                'position' : 'absolute',
                'z-index' : '125',
                'width'      : $(".m2-add-review-content").width(),
                'height'     : $(".m2-add-review-content").height(),
            });

            $.ajax({
                url: '',
                type: 'POST',
                data: formData,
                dataType: 'json',
                error: function () { $(".m2-add-review-loading").hide(); },
                success: function (data) {
                    $(".m2-add-review-loading").hide();

                    if (data.success !== undefined) {
                        m2Reviews.showSuccessSent();
                    }
                    else if (data.error !== undefined) {
                        $(".m2-add-review-content ._send_button > ._error").show().html(data.error);
                    }
                },
                cache: false,
                contentType: false,
                processData: false
            });
        });

        // _send_button
        $(".m2-add-review-content ._send_button > a").on("click", function(){
            $("form#form_review_add").submit()
        });

        // Choose files
        const fileSelector = document.getElementById('file-selector');
        fileSelector.addEventListener('change', (event) => {
            const files = event.target.files;

            $(".m2-add-review-content ._attach_button ._preview").html('');

            for (let i = 0; i < files.length ; i++)
            {
                if (files[i].type && files[i].type.indexOf('image') === -1) {
                    return;
                }

                const reader = new FileReader();
                reader.addEventListener('load', (event) => {
                    $(".m2-add-review-content ._attach_button ._preview").append('<span style="background-image: url(\''+ event.target.result +'\')"></span>');
                });
                reader.readAsDataURL(files[i]);
            }
        });
        $(".m2-add-review-content ._attach_button a").click(function(){
             $("#file-selector").click();
        });

        // Stars
        $(".m2-add-review-content ._stars > span").click(function(){
            $(".m2-add-review-content ._stars > span").removeClass("active");
            for (let i = 0; i <= $(this).index(); i++)
            {
                $(".m2-add-review-content ._stars > span:nth-child("+(i+1)+")").addClass("active");
            }

            $("form#form_review_add input[name='stars']").val($(this).index()+1);

            if ($(".m2-add-review-content ._stars > span.active").length > 0) {
                $(".m2-add-review-content ._hidden_block").show();
                $(".m2-add-review-content").css({ 'padding-bottom' : '0' });
                m2Reviews.centered();
            } else {
                $(".m2-add-review-content ._hidden_block").hidden();
            }
        });

        $.ajax({
            url: '/ajax/get_reviews_features',
            data: {
                'ajax' : 1,
            },
            dataType: 'json',
            type: 'post',
            error: function () {},
            success: function (response)
            {
                if (response.items !== undefined && response.items !== null) {
                    $(".m2-add-review-content ._features ._features_content").html('');
                    $.each(response.items, function(k, v){
                        $(".m2-add-review-content ._features ._features_content").append('<span data-id="'+ k +'">'+ v +'</span>');
                    });
                }

                $(".m2-add-review-content ._features ._features_content span").click(function(){
                    if ($(this).hasClass("active")) {
                        $(this).removeClass("active")
                    } else {
                        $(this).addClass("active")
                    }

                    let _features = [];
                    $(".m2-add-review-content ._features ._features_content span.active").each(function(){
                        _features.push($(this).data('id'));
                    });
                    $("form#form_review_add input[name='features']").val(_features.join(','));
                });
            }
        });

        this.$modal.show();
        this.$dark.show();
        this.centered();
    },

    showSuccessSent : function()
    {
        this.$modal.html('<div class="m2-success-sent">' +
                    '<div class="_top">' +
                        '<b>Спасибо за оценку</b>' +
                        '<a href="javascript:" onclick="m2Reviews.close()"><img src="/images/m2/close.svg" /></a>' +
                    '</div>' +
                    '<div class="_message">Скоро она пройдет модерацию и появится в профиле исполнителя. Если у нас появятся вопросы, мы с вами свяжемся.</div>' +
                    '<div class="_button">' +
                        '<a href="javascript:" onclick="m2Reviews.close()">Хорошо</a>' +
                    '</div>' +
                '</div>'
        );

        this.$modal.show();
        this.$dark.show();
        this.centered();
    },

    showSending : function()
    {

    },

    close : function()
    {
        document.body.classList.remove('-is-review-open')
        this.$modal.hide();
        this.$dark.hide();
    },

    centered : function()
    {
        let bodyHeight = $("body").height();
        let __fullHeight = $(window).height();
        if (__fullHeight < bodyHeight) {
            __fullHeight = bodyHeight;
        }

        if ($(window).width() < 768) {
            this.$modal.css({
                'width' : '100%',
                'left' : '0',
                'top' : 'auto'
            });
        }
        else if ($(window).width() < 1000) {
            this.$modal.css({
                'width' : '100%',
                'left' : '0',
                'top' : ( $(window).scrollTop() + ($(window).height() / 2 - $(this.$modal).height() / 2)) + 'px'
            });
        } else {
            this.$modal.css({
                'left' : ( $(window).width() / 2 - $(this.$modal).width() / 2 ) + 'px',
                'top' : ( $(window).scrollTop() + ($(window).height() / 2 - $(this.$modal).height() / 2)) + 'px'
            });
        }

        this.$dark.css({
            'height' : __fullHeight + 'px'
        });
    }

}

$().ready(function(){
    m2Reviews.checkDOM();
    // m2Reviews.showAddForm('51660', 'Keeper Keeper', '/avatars/51666_5d43d1f42e8c6.jpg');
    // m2Reviews.showSuccessSent();
    if (location.href.indexOf("to_review") > 0) {
        m2Reviews.clickByReviewBtn();
        $("html, body").animate({ scrollTop: $('.profile2-tabs').offset().top - 130 }, 1000);
    }
});
