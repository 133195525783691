window.masterSTL = {

    newTab : function(___url)
    {
        let win = window.open(___url, '_blank');
        win.focus();
    },

    main_page : function()
    {
        $(".mp_partners ._tabs a").click(function(){
            $(".mp_partners ._tabs a").removeClass("active");
            $(this).addClass("active");

            $(".mp_partners ._tabs_content div")
                    .removeClass("active")
                    .hide()
                .eq($(this).index())
                    .show()
                    .addClass("active");
        });

        $(".mp_search ._inputs ._input input[name='location']").on("keyup", function(){
            if ($(this).val().length === 0) {
                $(this).parents("form").find("input[name='location_id']").val("current");
            }
        });
    },

    resize : function()
    {
        /**
         * Catalog
         */
        let __catalogSelector1   = ".catalog2-items";
        let __catalogMapSelector = ".catalog2-map, .catalog2-map ._map";

        let $catalogItems = $(__catalogSelector1);
        if ($catalogItems.length > 0)
        {
            let _position = $catalogItems.offset();
            let _height =  $(window).height() - _position.top;

            $catalogItems.css({
                'height' : _height + 'px'
            });
            $(__catalogMapSelector).css({
                'height' : _height + 'px'
            });
            $("#map").css({
                'height' : _height+'px'
            });
        }

        /**
         * mp_services
         */
        if ($(".mp_services ._services > a span").length > 0)
        {
            $(".mp_services ._services > a span").each(function(){
                let heightParent = $(this).parent().height();
                let widthParent = $(this).parent().width();
                $(this).css({
                    'margin-left' : (widthParent/2 - $(this).width()/2 - parseInt($(this).css('padding-left'))) + 'px',
                    'margin-top'  : (heightParent/2 - $(this).height() - 10) + 'px'
                });
            });

            setTimeout(function(){
                $(".mp_services ._services > a span").each(function(){
                    let heightParent = $(this).parent().height();
                    let widthParent = $(this).parent().width();
                    $(this).css({
                        'margin-left' : (widthParent/2 - $(this).width()/2 - parseInt($(this).css('padding-left'))) + 'px',
                        'margin-top'  : (heightParent/2 - $(this).height() - 10) + 'px'
                    });
                });
            }, 1000);

            setTimeout(function(){
                $(".mp_services ._services > a span").each(function(){
                    let heightParent = $(this).parent().height();
                    let widthParent = $(this).parent().width();
                    $(this).css({
                        'margin-left' : (widthParent/2 - $(this).width()/2 - parseInt($(this).css('padding-left'))) + 'px',
                        'margin-top'  : (heightParent/2 - $(this).height() - 10) + 'px'
                    });
                });
            }, 3000);
        }

        /**
         * Top Bar FIXED
         */
        if ($(window).width() < 768)
        {
            let $navbar = $(".navbar-fluid").length > 0 ? $(".navbar-fluid") : null;
            if ($navbar === null) {
                $navbar = $(".navbar-mp").length > 0 ? $(".navbar-mp") : null;
            }

            if ($navbar !== null && $navbar.length > 0
                && $(".why-page").length === 0
                && $(".why-page2").length === 0
                && $(".covid-outer").length === 0
                && $(".business-feed-page").length === 0
                && $(".profile2-page").length === 0
            )
            {
                if ($navbar.hasClass("navbar-poster")) {
                    return;
                }
                if ($(".client-now-block").length > 0) {
                    return;
                }

                if ($("body").hasClass("body-inner")) {
                    return;
                }

                if ($(".mp_search_video").length >= 1 || $("#blog_menu_fixed").length >= 1 || $(".free-solutions-page ._bg_mobile").length >= 1) {
                    // Nothing
                } else {
                    $navbar.css({
                        'position'      : 'fixed',
                        'background'    : '#fff',
                        'width'         : '100%',
                        'z-index'       : '100',
                        'border-bottom' : '1px solid rgb(240, 240, 240)',
                        'box-shadow'    : 'rgb(170, 170, 170) 1px 1px 5px',
                    });


                    if ($("#app > main.noPadding").length === 0 && !$('body').hasClass('-is-hide-header')) {
                        $("#app > main").css({'padding-top': $navbar.height() + "px"});
                    }
                }

                // About Page
                $(".about-press2").css({ 'padding-top' : "20px" });
            }
        }
    },

    add_bookmark : function(a)
    {
        let pageTitle=document.title;
        let pageURL=document.location;
        try {
            // Internet Explorer solution
            eval("window.external.AddFa-vorite(pageURL, pageTitle)".replace(/-/g,''));
        }
        catch (e) {
            try {
                // Mozilla Firefox solution
                window.sidebar.addPanel(pageTitle, pageURL, "");
            }
            catch (e) {
                // Opera solution
                if (typeof(opera)=="object") {
                    a.rel="sidebar";
                    a.title=pageTitle;
                    a.url=pageURL;
                    return true;
                } else {
                    // The rest browsers (i.e Chrome, Safari)
                    alert('Нажмите ' + (navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Cmd' : 'Ctrl') + '+D для создания закладки.');
                }
            }
        }
    },

    initStyleSelect : function(){
        /**
         * .form-style-select-group .selected-value
         */
        $(".form-style-select-group .selected_value").off("click").on("click", function()
        {
            $(".select_values").hide();

            $(this).parent().find(".select_values").show();

            setTimeout(function() {

                $(window).click(function() {
                    $(".select_values").hide();
                });

                $('.form-style-select-group').click(function(event){
                    event.stopPropagation();
                });

            }, 250);

            $(this).parent().find(".select_values > div").on("click", function()
            {

                $(this).parents(".form-style-select-group")
                    .find(".selected_input").val( $(this).data("duration") )
                    .parent().find(".selected_value").html( $(this).html() );

                $(".select_values").hide();

            });
        });
    },

    initStyleSelect2 : function(){
        /**
         * .form-style2-select-group .selected-value
         */
        $(".form-style2-select-group .selected_value").off("click").on("click", function()
        {
            let offset = $(this).position();

            $(this).parent().find(".select_values").show().css({
                'top' : offset.top + 'px'
            });

            setTimeout(function() {
                $("body").one("click", function () {
                    $(".select_values").hide();
                });
            }, 250);

            $(this).parent().find(".select_values > div").on("click", function(){
                $(this).parents(".form-style2-select-group")
                    .find(".selected_input").val( $(this).data("duration") )
                    .parent().find(".selected_value").text( $(this).text() );
            });
        });
    },

    register_action_after_login : function(return_url, return_method, return_param)
    {
        $.ajax({
            url: '/ajax/register_action_after_login',
            data: {
                'ajax'          : 1,
                'return_url'    : return_url,
                'return_method' : return_method,
                'return_param'  : return_param,
            },
            dataType: 'json',
            type: 'post',
            error: function () { },
            success: function (response) { }
        });
    },

    serializeFormToObject : function($selector)
    {
        let __object = {};

        $selector.find("input, textarea").each(function()
        {
            let _name  = $(this).attr('name');
            let _value = $(this).val();
            if (_name === undefined || _name === null || _name.length === 0) {
                return;
            }

            if (_name.indexOf('[]') > 0)
            {
                _name = _name.replace("[]", "");

                if (__object[_name] === undefined) {
                    __object[_name] = [];
                }
                __object[_name].push(_value);
            }
            else
            {
                __object[_name] = _value;
            }
        });

        return __object;
    },

    _current_lat : null,
    _current_lng : null,
    requestGeoLocation : function()
    {
        navigator.geolocation.getCurrentPosition(
            function(position)
            {
                if (position !== null && position.coords !== undefined && position.coords.latitude !== undefined)
                {
                    masterSTL._current_lat = position.coords.latitude;
                    masterSTL._current_lng = position.coords.longitude;

                    $.ajax({
                        url: '/location/detected',
                        data: {
                            'lat' : masterSTL._current_lat,
                            'lng' : masterSTL._current_lng
                        },
                        dataType: 'json',
                        type: 'post',
                        error: function(){},
                        success: function (response){
                            if (response.redirect) {
                                window.location = response.redirect;
                            }
                        }
                    });

                    if ($("#is_null_last_location_id").length > 0) {
                        $(".mp_search input.__location_results").val('');
                        $(".mp_search input.__location_id").val('current');
                    }
                }
            },
            function(error){}
        );
    },
    showPopup: function() {
        if ($(".welcome-how-mobile").length > 0) {
            $(".welcome-how-mobile ._close, .welcome-how-mobile ._no_thanks a").on("click", function(){
                $(".welcome-how-mobile").css({ 'display' : 'none' });

                $("html, body").css({
                    'height'   : '',
                    'overflow' : 'auto'
                });
                if (!+lat || !+lng) {
                    masterSTL.requestGeoLocation();
                }
            });

            $(".welcome-how-mobile").css({
                'display' : 'block'
            });
            $("html, body").css({
                'position' : 'relative',
                'height'   : '100%',
                'overflow' : 'hidden'
            });
        } else {
            if (!+lat || !+lng) {
                masterSTL.requestGeoLocation();
            }
        }
    },
    unreadNotifyClick : function(without_redirect)
    {
        // $("#unreadNotifyModal").modal('hide');
        // $("._notify_bar_message").show();

        $.ajax({
            url: '/ajax/unread_notify_click',
            data: {},
            dataType: 'json',
            type: 'post',
            error: function () {},
            success: function (response) {
                if (without_redirect === undefined) {
                    location.href = '/messages';
                }
            }
        });
    },
    restrictedHDModal : function()
    {
        $('#restrictedMessagesModal').modal('show');
    },

    restrictedClientsModal : function()
    {
        $('#restrictedClientsModal').modal('show');
    }

};

var masterLocations = {

    __locationSearchTimer : null,
    locationSearch : function($input)
    {
        clearTimeout(masterLocations.__locationSearchTimer);

        masterLocations.__locationSearchTimer = setTimeout(function(){
            $.ajax({
                url: '/location/find',
                data: {
                    'q' : $input.val()
                },
                dataType: 'json',
                type: 'post',
                error: function(){},
                success: function (response)
                {
                    if (response.items !== undefined && response.items !== null)
                    {
                        let $location_results = $("#location_results");
                        $location_results.html('');

                        if ($location_results.parents(".catalog2-search-location").length === 0) {
                            $location_results.css('width', ($input.outerWidth()) + 'px');
                        }

                        if (response.order !== undefined && response.order !== null) {
                            $.each(response.order, function(kk,vv) {
                                $location_results.append('<a href="javascript:" onclick="masterLocations.setLocation($(this))" data-id="' + vv + '">' + response.items[vv] + '</a>');
                            });
                        } else {
                            $.each(response.items, function (k, v) {
                                $location_results.append('<a href="javascript:" onclick="masterLocations.setLocation($(this))" data-id="' + k + '">' + v + '</a>');
                            });
                        }
                        $location_results.slideDown();

                        $(window).one("click", function() {
                            $("#location_results").slideUp();
                        });
                    }
                }
            });
        }, 300, $input);
    },

    setLocation : function ($elem) {
        let $__location_results = $(".__location_results");
        $("#location_results").hide();
        $__location_results.val( $elem.text() );
        $(".__location_id").val( $elem.data('id') );
        // $location_results.slideUp();

        $.ajax({
            url: '/location/set_location',
            data: {
                'location_id' : $elem.data('id')
            },
            dataType: 'json',
            type: 'post',
            error: function(){},
            success: function (response){}
        });

        if ($__location_results.parents(".catalog2-search").length > 0)
        {
            __blockedOneClick = true;
            if ($(window).width() < 768) fs2.loadingV2();
            else $(".catalog2-search-location").html('<div class="_loader"><img src="/images/loader.svg" alt="" /> <span>Загрузка...</span></div>');
            $("#catalogForm").submit();
        }
    }

};

/**
 * masterSearchKeys
 */
var masterSearchKeys = {

    __searchTimer : null,
    search : function($input)
    {
        clearTimeout(masterSearchKeys.__searchTimer);

        masterSearchKeys.__searchTimer = setTimeout(function(){

            let q = $input.val();

            $.ajax({
                url: '/ajax/search_keys',
                data: {
                    'q' : q
                },
                dataType: 'json',
                type: 'post',
                error: function(){},
                success: function (response)
                {
                    if (response.items !== undefined && response.items !== null && Object.keys(response.items).length > 0)
                    {
                        let $results = $("#__search_keys_results");

                        /*$results.html('<span>' +
                            '<a href="javascript:"><span class="_v">Услуги</span><span class="redRec"></span></a>' +
                            '<i><img src="/images/welcome/arrow-bottom.svg" alt="" /></i>' +
                            '</span>');*/

                        $results.html('<span>' +
                            '<a href="javascript:"><img src="/images/welcome/services_text.svg" alt="" width="55" /></a>' +
                            '<i><img src="/images/welcome/arrow-bottom.svg" alt="" /></i>' +
                            '</span>');

                        // let $callerElem = $results.parent().find(".__need_search_keys");
                        if ($results.prev().length > 0) {
                            $results.css('width', ($results.prev().outerWidth()) + 'px');
                        }

                        $.each(response.items, function(k,v){
                            let __s = new RegExp("(" + q + ")","gi");
                            let val = v[0].replace(__s, "<b>$1</b>")
                            $results.append('<a href="javascript:" onclick="masterSearchKeys.click($(this))" data-id="'+k+'" data-service-id="'+ v[1] +'">'+val+'</a>');
                        });
                        $results.slideDown();

                        $(window).one("click", function() {
                            $("#__search_keys_results").slideUp();
                        });

                        // redRec
                        let $redRec = $("#__search_keys_results > span .redRec");
                        if ($redRec.length > 0) {
                            setTimeout(function($redRec){
                                let __pos = $redRec.prev().position();
                                $redRec.css({
                                    'width'  : $redRec.prev().outerWidth()+'px',
                                    'left'   :  __pos.left + 'px',
                                    'margin-top' :  '7px',
                                    'height' : '9px'
                                });
                            }, 300, $redRec);
                        }

                    } else {
                        $("#__search_keys_results").slideUp();
                    }
                }
            });
        }, 300, $input);
    },

    click : function ($elem) {
        $(".__need_search_keys").val( $elem.text() );

        $("#__search_keys_results").slideUp();

        if ($elem.parents("form").length > 0)
        {
            if ($elem.attr('data-service-id') !== undefined) {
                $elem.parents("form").find("input[name='service_id']").val( $elem.attr('data-service-id') );
            }

            $elem.parents("form").submit();
        }
    }

};

/**
 * catalogSearchKeys
 */
var catalogSearchKeys = {

    __searchTimer : null,
    search : function($input)
    {
        clearTimeout(catalogSearchKeys.__searchTimer);

        let q = $input.val();

        catalogSearchKeys.__searchTimer = setTimeout(function(){
            $.ajax({
                url: '/ajax/search_keys',
                data: {
                    'q' : q
                },
                dataType: 'json',
                type: 'post',
                error: function(){},
                success: function (response)
                {
                    let $results = $("#__catalog_keys_results");

                    if (response.items !== undefined && response.items !== null && Object.keys(response.items).length > 0)
                    {
                        $results.html('');

                        // let $callerElem = $results.parent().find(".__need_search_keys");
                        if ($results.parent().length > 0)
                        {
                            let _prevPos = $results.parent().position();
                            $results.css({
                                'width' : ($results.parent().outerWidth()) + 'px',
                                'left'  : (_prevPos.left) + 'px',
                                'top'   : (_prevPos.top + $results.parent().height()) + 'px'
                            });
                        }

                        $results.html('<span>' +
                            '<a href="javascript:"><img src="/images/welcome/services_text.svg" alt="" width="55" /></a>' +
                            '<i><img src="/images/welcome/arrow-bottom.svg" alt="" /></i>' +
                            '</span>');

                        $.each(response.items, function(k,v){
                            let __s = new RegExp("(" + q + ")","gi");
                            let val = v[0].replace(__s, "<b>$1</b>")
                            $results.append('<a href="javascript:" onclick="catalogSearchKeys.click($(this))" data-id="'+k+'" data-service-id="'+ v[1] +'">'+val+'</a>');
                        });

                        /*$.each(response.items, function(k,v){
                            $results.append('<a href="javascript:" onclick="catalogSearchKeys.click($(this))" data-id="'+k+'">'+v+'</a>');
                        });*/

                        $results.slideDown();

                        $(window).one("click", function() {
                            $("#__catalog_keys_results").slideUp();
                        });
                    } else {
                        $results.slideUp();
                    }
                }
            });
        }, 300, $input);
    },

    click : function ($elem) {
        $(".__search_keys").val( $elem.text() );
        $("#__catalog_keys_results").slideUp();

        if ($elem.parents("form").length > 0)
        {
            if ($elem.attr('data-service-id') !== undefined) {
                $elem.parents("form").find("input[name='service_id']").val( $elem.attr('data-service-id') );
            }

            $elem.parents("form").submit();
        }
    }

};

var __blockedOneClick = false;

$().ready(function(){
    let url = new URL(window.location.href);
    let restricted = url.searchParams.get("restricted");
    if (restricted) {
        masterSTL.restrictedClientsModal();
    }
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    jQuery.expr[':'].Contains = function(a, i, m) {
      return jQuery(a).text().toUpperCase()
          .indexOf(m[3].toUpperCase()) >= 0;
    };



    $('[data-toggle="tooltip"]').tooltip();

    $('.phone_mask').mask('+7 (000) 000-00-00');
    $('.__mask_time').mask('00:00');

    $(window).on("resize", function(){
        masterSTL.resize();
    });
    masterSTL.resize();
    setTimeout(function(){ masterSTL.resize(); }, 1000);

    // Main Page
    masterSTL.main_page();



    let $__need_search_keys = $(".__need_search_keys");
    if ($__need_search_keys.length > 0) {
        $__need_search_keys.on("keyup", function(){  masterSearchKeys.search($(this)); });
                            //.on("click", function(){  masterSearchKeys.search($(this)); })
                            //.on("focus", function(){  masterSearchKeys.search($(this)); });
    }

    /**
     * business-photos-thumbs
     */
    $(".business-photos-thumbs a").on("click", function(){
        $("#carouselBusiness .carousel-indicators li").eq($(this).index()).click();
    });

    /**
     * Mobile menu
     */
    $(".toggle-mobile-menu").on("click", function(){
        let $elem = $(".top-mobile-menu ._dropdown");
        if ($elem.hasClass("__opened")) {
            $elem.removeClass("__opened").slideUp();
        } else {
            $elem.addClass("__opened").slideDown();
        }
    });
    $(".top-mobile-menu ._dropdown ._hide").on("click", function(){
        $(".top-mobile-menu ._dropdown").removeClass("__opened").slideUp();
    });

    /**
     * Direct chat
     */
    $(".direct_chat ._write_message ._textarea > ._text")
        .on("focus", function(){
            $(this).prev().hide();
            $(".direct_chat ._write_message ._textarea > ._send_message_btn").show();
        })
        .on("blur", function(){
            if ($(this).text().length === 0) {
                $(this).text("");
                $(this).prev().show();
                $(".direct_chat ._write_message ._textarea > ._send_message_btn").hide();
            }
        });
    $("._text_placeholder").click(function(){
        $(this).next().focus();
    });

    $("._write_message ._send_message_btn_active").on("click", function(){
        let $textBlock = $("._write_message .__text");
        if ($textBlock.text().length === 0) {
            return false;
        }

        if ($(this).hasClass("__working")) {
            return false;
        }

        $(this)
                .css({ "width" : $(this).outerWidth()+"px" })
                .addClass("__working")
                .removeClass("btn-style")
                .html('<img src="/images/loader.svg" style="width:16px" /> &nbsp;Отправка');

        $.ajax({
            url  : '/messages/send_message',
            data : {
                msg         : $textBlock.html().replace(/<div>/gi,'<br>').replace(/<\/div>/gi,''),
                chat_id     : $textBlock.data("chat-id"),
                from_direct : 1
            },
            dataType : 'json',
            type     : 'post',
            error : function(){ },
            success : function(response)
            {
                $textBlock.text('');
                $(".search_block ._img_chat_line .direct_chat ._buttons").hide();

                $("._write_message ._send_message_btn_active")
                    .removeClass("__working")
                    .addClass("btn-style")
                    .html('<i class="fas fa-paper-plane"></i> Отправить');
            }
        });

    });

    /**
     * Telegram
     */
    $(".__telegram_get_code").on("click", function(){
        var $elem = $(this).parent();
        $elem.html('<div class="_loader"><img src="/images/loader.svg" style="max-width:66px;" /> <span>Генерация...</span></div>');
        $.ajax({
            url: '/tg_get_code',
            data: {},
            dataType: 'json',
            type: 'post',
            error: function(){},
            success: function (response) {
                if (response.html !== undefined && response.html !== null) {
                    $elem.html(response.html);
                }

                $("#telegramConnectModal .__change_btn_tg_text_close").text('закрыть');
            }
        });
    });

    // Disable telegram's popup
    /*let $telegramConnectModal = $("#telegramConnectModal");
    if ($telegramConnectModal.length > 0)
    {
        $telegramConnectModal.on('hidden.bs.modal', function () {
            $.ajax({
                url: '/tg_connect_later',
                data: {'ajax': 1, 'tg_connect_later': 1},
                dataType: 'json',
                type: 'post',
                error: function () {
                },
                success: function (response) {
                }
            });
        });

        $telegramConnectModal.modal('show');
    }*/

    let $unsuccessfulRebillModal = $("#unsuccessfulRebillModal");
    if ($unsuccessfulRebillModal.length > 0)
    {
        /*$.ajax({
            url: '/ajax/unsuccessful_rebill_session',
            data: {},
            dataType: 'json',
            type: 'post',
            error: function () {},
            success: function (response) {
                $unsuccessfulRebillModal.modal('show');
            }
        });*/

        axios.post('/ajax/unsuccessful_rebill_session', {
            unsuccessful_rebill_session : 1
        })
        .then((response) => {
            $unsuccessfulRebillModal.modal('show');
        });
    }

    /**
     * Favorites
     */
    $(".business-right ._to_fav").click(function(){
        let $elem      = $(this);
        let __prevText = $elem.find("span").text();
        let __prevImg  = $elem.find("img").attr('src');

        $(this).find("img").attr('src', '/images/loader.svg');

        $.ajax({
            url: '/ajax/set_favorite',
            data: {
                'ajax'        : 1,
                'business_id' : $(this).data('business-id')
            },
            dataType: 'json',
            type: 'post',
            error: function () {
                $elem.find("span").text(__prevText);
                $elem.find("img").attr('src', __prevImg);
            },
            success: function (response) {
                if (response.fav !== undefined && response.fav !== null)
                {
                    $elem.find("span").text('В избранном');
                    $elem.find("img").attr('src', '/images/fav1.png');
                }
                else if (response.un_fav !== undefined && response.un_fav !== null)
                {
                    $elem.find("span").text('В избранное');
                    $elem.find("img").attr('src', '/images/fav00.png');
                }
                else if (response.need_auth !== undefined && response.need_auth !== null)
                {
                    //$("#needRegModal").modal('show');
                    fs2.load_who();

                    $elem.find("span").text(__prevText);
                    $elem.find("img").attr('src', __prevImg);
                }
                else {
                    $elem.find("span").text(__prevText);
                    $elem.find("img").attr('src', __prevImg);
                }
            }
        });
    });

    /**
     * Feed
     */
    $(".business-right ._to_feed, .business-center-subscribe-btn ._to_feed").click(function(e){
        let $elem      = $(this);
        let __prevText = $elem.find("span").text();
        let __prevImg  = $elem.find("img").attr('src');

        $(this).find("img").attr('src', '/images/loader.svg');
        $elem.removeClass("_to_feed0").removeClass("_to_feed1");

        $.ajax({
            url: '/ajax/set_feed',
            data: {
                'ajax'        : 1,
                'business_id' : $(this).data('business-id')
            },
            dataType: 'json',
            type: 'post',
            error: function () {
                $elem.find("span").text(__prevText);
                //$elem.find("img").attr('src', __prevImg);
            },
            success: function (response) {
                if (response.fav !== undefined && response.fav !== null)
                {
                    $elem.find("span").text('Отписаться');
                    $elem.removeClass("_to_feed1").addClass("_to_feed0");
                    //$elem.find("img").attr('src', '/images/fav1.png');
                }
                else if (response.un_fav !== undefined && response.un_fav !== null)
                {
                    $elem.find("span").text('Подписаться');
                    $elem.removeClass("_to_feed0").addClass("_to_feed1");
                   // $elem.find("img").attr('src', '/images/fav00.png');
                }
                else if (response.need_auth !== undefined && response.need_auth !== null)
                {
                    //$("#needRegModal").modal('show');
                    fs2.load_who();

                    $elem.find("span").text(__prevText);
                   // $elem.find("img").attr('src', __prevImg);
                }
                else {
                    $elem.find("span").text(__prevText);
                   // $elem.find("img").attr('src', __prevImg);
                }
            }
        });
    });


    masterSTL.showPopup();

    /**
     * business-services-header
     */
    $(".to-services-header-btn").on("click", function() {
        $("html, body").animate({
            scrollTop: $(".business-services-header").offset().top
        }, 500);
    });

    /**
     * #unreadNotifyModal
     */
    let $unreadNotifyModal = $("#unreadNotifyModal");
    if ($unreadNotifyModal.length > 0)
    {
        if ($unreadNotifyModal.data("timeout") !== undefined)
        {
            setTimeout(function(){
                $unreadNotifyModal.modal('show');
            }, parseInt($unreadNotifyModal.data("timeout")));
        }
        else
        {
            $unreadNotifyModal.modal('show');
        }

        $unreadNotifyModal.on('hidden.bs.modal', function () {
            masterSTL.unreadNotifyClick(true);
        });
    }

    /**
     * #changeMyLinkModal
     */
    $("#changeMyLinkModal input[name='set_new_url']").on("keydown", function(){ $("#changeMyLinkModal .__preview_link").text("https://masterstl.ru/m/" + $(this).val()); });
    $("#changeMyLinkModal input[name='set_new_url']").on("keyup", function(){   $("#changeMyLinkModal .__preview_link").text("https://masterstl.ru/m/" + $(this).val()); });
    $("#changeMyLinkModal input[name='set_new_url']").on("change", function(){  $("#changeMyLinkModal .__preview_link").text("https://masterstl.ru/m/" + $(this).val()); });

    $("#changeMyLinkModal ._save_btn_block button").on("click", function(){
        let $elem = $(this);
        $elem.text("Загрузка...");
        let newUrl = $("#changeMyLinkModal input[name='set_new_url']").val();

        $.ajax({
            url: '/ajax/set_new_my_url',
            data: {
                'ajax'        : 1,
                'set_new_url' : newUrl
            },
            dataType: 'json',
            type: 'post',
            error: function () {
                $elem.text("Сохранить");
                $("#changeMyLinkModal ._error_block").text("Проблемы с интернетом, не получается обновить.").show();
            },
            success: function (response) {
                if (response.success !== undefined && response.success !== null)
                {
                    $("#changeMyLinkModal ._error_block").hide();
                    $elem.text("Успешно!");
                    location.reload();
                }
                else if (response.err !== undefined && response.err !== null) {
                    $("#changeMyLinkModal ._error_block").text(response.err).show();
                    $elem.text("Сохранить");
                }
                else {
                    $("#changeMyLinkModal ._error_block").text("Что-то пошло не так, не получается обновить.").show();
                    $elem.text("Сохранить");
                }
            }
        });

    });

    $("#to-up").click(function(){
        $("#to-up").hide();
        $("html, body").animate({ scrollTop: 0 }, "fast");
        $(".catalog2-items").animate({ scrollTop: 0 }, "fast");
    });
    $(".modal-restricted").on('shown.bs.modal', function () {
        $("html, body").css({
            'position' : 'relative',
            'height'   : '100%',
            'overflow' : 'hidden'
        });
    })
    .on('hidden.bs.modal', function () {
         $("html, body").css({
            'position' : '',
            'height'   : '',
            'overflow' : 'auto'
        });
    });
});
