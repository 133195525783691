document.addEventListener("DOMContentLoaded", function(event) {
    let count = window.innerWidth >= 768 ? 3 : 5;
    console.log(count,window.innerWidth);
    let page = 0;
    function loadLatest() {
        axios.get('/ajax/latest_registered', { params: {count, page} })
                .then((response) => {
                    if (response.data.count) {
                        $('.js-latest-wrapper').append(response.data.html);
                    } else {
                        $('.js-load-lates').addClass('d-none');
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
    }
    document.addEventListener('click', function(event) {
        let btn = event.target.closest('.js-load-lates');
        if (btn) {
            page = +btn.dataset.page;
            loadLatest();
            btn.dataset.page = page + 1;
        }
    });
    if (document.querySelector('.js-latest-wrapper')) {
        loadLatest();
    }
});
