<template>
    <div class="personal_messages_outer">
        <div class="personal_messages">
            <div class="_contacts">
                <div class="scroll">
                    <p v-if="!loaded" style="font-size: 11px;padding:15px;text-align:center;">Loading...</p>

                    <div class="_items">
                        <div class="_item" v-for="contact in contactsList" :data-chat-id="contact.chat_id" @click="clickContact" v-bind:class="{ not_read: contact.not_read, 'active' : chat_id == contact.chat_id }">
                            <div class="_avatar">
                                <div class="_image" v-bind:style="{ backgroundImage: 'url(' + contact.avatar + ')' }"></div>
                            </div>
                            <div class="_info">
                                <div class="_login">
                                    {{ contact.login }}
                                    <!--<span v-if="contact.not_read" class="not_read_count">({{ contact.not_read }})</span>-->
                                </div>
                                <div class="_date">{{ contact.last_date }}</div>
                            </div>
                        </div>
                    </div>

                    <p v-if="loaded && !contactsList.length" style="font-size: 11px;padding:15px;text-align:center;">No contacts.</p>
                </div>
            </div>
            <div class="_chat">
                <div class="chat_box">
                    <div class="chat_box_wrapper scroll">
                        <div class="">
                            <div v-for="item in messages">
                                <div class="_c">
                                    <div><span :style="{ backgroundImage: `url('${item.avatar}')` }" onclick="location.href=this.getAttribute('href')" :href="'/to_user_business/' +item.user_id+'.html'"></span></div>
                                    <div v-html="item.msg"></div>
                                    <div v-if="item.blocked == undefined || !item.blocked">{{item.date}}</div>
                                </div>
                            </div>
                            <p v-if="msg_loading" style="font-size: 11px;padding:15px;text-align:center;">Loading...</p>
                        </div>
                    </div>

                    <div class="p-15" v-if="showSubmitForm && !blockedWrite">
                        <textarea v-model="txtMsg" @keydown="inputHandler"></textarea>
                        <span class="btn btn-sm btn-primary float-right" v-on:click="sendMessage" v-html="sendButtonHtml"></span>
                        <div class="_notice">ENTER - Отправить сообщение</div>
                        <div class="_notice">(CTRL или SHIFT)+ENTER - Новая строка</div>
                    </div>

                    <div class="chat-restrictions-block" v-if="blockedWrite">
                        <div class="no-premium">
                            <div>У вас непрочитанное сообщение от клиента!</div>
                            <div>
                                Для прочтения необходимо приобрести подписку.
                            </div>
                            <div>Это сообщение возможно может быть очень важным для вас и вовремя отвеченные вопросы по бронированию или услугам мастера, могут принести в вашу копилку еще одного клиента.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="settings-subscription-buy" style="margin-top: 15px; text-align: left;" v-if="blockedWrite">
            <div class="_info">
                <h5>Купить подписку</h5>
                <p>Аккаунты оплатившие подписку получают:</p>
                <ul>
                    <li><i class="fas fa-check"></i> Неограниченный доступ ко всему функционалу системы</li>
                    <li><i class="fas fa-check"></i> Возможность принимать заказы внутри системы</li>
                    <li><i class="fas fa-check"></i> Увеличение клиентской базы</li>
                </ul>
            </div>
            <div class="_buy_block">
                <div class="_premium_cost">
                    <div>
                        Итого:
                    </div>
                    <div>
                        <b>299 руб.</b> / месяц
                    </div>
                </div>
                <a href="/to_pay" class="btn btn-style">Перейти к оплате <i class="fas fa-arrow-right"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            'writeToId' : {
                type: Number
            },
            'myAvatar' : {
                type: String
            },
            'blockedWrite' : {
                type: Boolean
            },
            'havePremium' : {
                type: Boolean
            }
        },
        data() {
            return {
                contactsList: [],
                messages: [],
                txtMsg: "",
                msg_loading: false,
                loaded: false,
                sendButtonHtml: '<i class="fa fa-envelope-o"></i> Отправить',
                sending: false,
                chat_id: false,
                currentChannel: null,
                login: null,
                user_id: null,
                showSubmitForm: false,
            }
        },

        /*computed: {d
            activeClass : function() {
                console.log(this);
                return {
                    //active: this.chat_id === this.currentTarget.getAttribute('data-chat-id')
                }
            }
        },*/

        mounted()
        {
            axios.post('/messages/ajax_init_chat', { writeToId : this.writeToId })
                    .then((response) => {
                        if (response.status === 200)
                        {
                            this.loaded = true;

                            if (response.data.login !== undefined && response.data.login !== null) {
                                this.login = response.data.login;
                            }
                            if (response.data.user_id !== undefined && response.data.user_id !== null) {
                                this.user_id = response.data.user_id;

                                window.Echo.private('chat_bar.' + this.user_id)
                                            .listen('ChatBarMessage', (query) =>
                                            {
                                                this.updateContactsList();
                                            });
                            }

                            if (response.data.last_chat_id !== undefined && response.data.last_chat_id !== null)
                            {
                                if (response.data.have_blocked_messages !== undefined && response.data.have_blocked_messages === true) {
                                    this.blockedWrite = true;
                                }/* else {
                                    this.blockedWrite = false;
                                }*/

                                this.messages     = response.data.last_chat_messages;

                                this.changeChannel(response.data.last_chat_id);
                                this.scrollBottom();

                                setTimeout(() => {
                                    this.updateContactsList();
                                }, 1000);
                            }

                            if (response.data.contactsList !== undefined && response.data.contactsList !== null)
                            {
                                this.contactsList = response.data.contactsList;

                                // Set first active
                                setTimeout(function() {
                                    let firstElem = document.querySelector('._contacts ._items ._item:first-child');
                                    if (firstElem !== null) {
                                        document.querySelector('._contacts ._items ._item:first-child').classList.add('active');
                                    }
                                }, 250);
                            }
                        }
                    });

            this.scrollBottom();
        },

        methods : {
            /**
             * openChannel
             */
            clickContact(e)
            {
                let new_chat_id = e.currentTarget.getAttribute('data-chat-id');

                // Active class
                let $divs = document.querySelector('._contacts div.active');
                if ($divs !== null) {
                    document.querySelector('._contacts div.active').classList.remove('active');
                }
                document.querySelector('._contacts div[data-chat-id="'+new_chat_id+'"]').classList.add('active');

                this.changeChannel(new_chat_id);

                this.messages = [];

                this.msg_loading = true;
                axios.post('/messages/ajax_get_messages', {
                    'chat_id' : this.chat_id
                })
                    .then((response) => {
                        this.msg_loading = false;

                        if (response.status === 200)
                        {
                            if (response.data.have_blocked_messages !== undefined && response.data.have_blocked_messages === true) {
                                this.blockedWrite = true;
                            }/* else {
                                this.blockedWrite = false;
                            }*/

                            if (response.data.messages !== undefined && response.data.messages !== null)
                            {
                                this.messages     = response.data.messages;
                                this.scrollBottom();
                            }

                            setTimeout(() => {
                                this.updateContactsList();
                            }, 1000);
                        }
                    });
            },
            activeClass()
            {
                if (this.chat_id == parseInt(e.currentTarget.getAttribute('data-chat-id'))) {
                    return 'active';
                } else {
                    return '';
                }
            },
            /**
             * openChannel
             */
            changeChannel(new_chat_id)
            {
                if (this.chat_id != null) {
                    window.Echo.leave('chat.' + this.chat_id);
                }

                this.chat_id = new_chat_id;
                this.showSubmitForm = true;

                window.Echo.private('chat.' + this.chat_id)
                        .listen('ChatMessage', (query) =>
                        {
                            // check on already exists
                            for (let i = 0; i < this.messages.length; i++){
                                if (parseInt(query.id) === parseInt(this.messages[i].id)) {
                                    return;
                                }
                            }

                            // Spec Mode | May be temp
                            if (!this.havePremium) {
                                location.reload();
                            }
                            else
                            {
                                this.messages.push(query);
                                this.$forceUpdate();
                                this.scrollBottom();

                                if (query.data !== undefined && query.data.have_blocked_messages !== undefined && query.data.have_blocked_messages === true) {
                                    this.blockedWrite = true;
                                }
                            }
                        });
            },
            /**
             * scrollBottom
             */
            scrollBottom() {
                setTimeout(()=>{
                    let container = this.$el.querySelector(".chat_box_wrapper");
                    container.scrollTop = container.scrollHeight;
                }, 250);
            },
            /**
             * updateContactsList
             */
            updateContactsList()
            {
                axios
                    .post('/messages/get_contacts_list', {
                        'writeToId' : this.writeToId
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.contactsList = response.data.contactsList;
                            this.$forceUpdate();

                            if (response.data !== undefined && response.data.have_blocked_messages !== undefined && response.data.have_blocked_messages === true) {
                                this.blockedWrite = true;
                            }
                        }
                    });
            },
            /**
             * sendMessage
             */
            sendMessage()
            {
                if (this.chat_id === null || this.txtMsg.length === 0) {
                    return;
                }

                if (this.sending) {
                    return;
                }

                this.sending = true;
                this.sendButtonHtml = '<img src="/images/loader.svg" style="width:16px;filter:invert(100%);" /> &nbsp;Отправка';

                axios
                    .post('/messages/send_message', {
                        msg     : this.txtMsg,
                        chat_id : this.chat_id
                    })
                    .then((response) => {

                        this.sending = false;
                        this.sendButtonHtml = '<i class="fa fa-envelope-o"></i> Отправить';

                        if (response.status === 200) {
                            let obj = {
                                date    : 'только что',
                                login   : this.login,
                                user_id : this.user_id,
                                msg     : this.txtMsg,
                                avatar  : this.myAvatar
                            };

                            this.messages.push(obj);

                            this.txtMsg = "";
                            this.scrollBottom();

                            if (response.data !== undefined && response.data.have_blocked_messages !== undefined && response.data.have_blocked_messages === true) {
                                this.blockedWrite = true;
                            }
                        }
                    });
            },
            inputHandler(e) {
                // CTRL-SHIFT+ENTER
                if (e.keyCode === 13 && ( e.ctrlKey || e.shiftKey )) {
                    this.txtMsg += "\n";
                }
                // ENTER WITHOUT CTRL
                else if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
                    e.preventDefault();
                    this.sendMessage();
                }
            },
        }
    }
</script>
