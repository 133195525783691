let townInputs = document.querySelectorAll('.js-town-input');
if (townInputs.length) {
    townInputs.forEach(t => {
        t.onkeydown = debounce(() => {searchTown(t)}, 300);
    })

    function searchTown(townInput) {
        let wrapper = townInput.closest('.js-town-input-wrapper');
        let townResults = wrapper.querySelector('.js-town-results');
        let service = null;
        let serviceElement = document.querySelector('[name="service"]');
        if (serviceElement) {
            service = serviceElement.value;
        }
        let theme = null;
        let themeElement = document.querySelector('[name="theme"]');
        if (themeElement) {
            theme = themeElement.value;
        }
        let text = townInput.value;
        if (text.length >= 3) {
            $(wrapper).find('.js-search-themes').slideUp();
            axios.post('/ajax/towns', { text, service, theme })
                .then((response) => {
                    $(townResults).html('');
                    let items = response.data.items || null;
                    if (items.length) {

                        items.forEach(item => {
                            $(townResults).append(`<a href="${item.path}" class="" data-id="${item.id}" data-name="${item.name}">
                                <span class="b-block">${item.text}</span>
                                <small class="text-muted d-block">${item.region}</small>
                            </a>`);
                        });
                        $(townResults).slideDown();
                    } else {
                        $(townResults).slideUp();
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        } else {
            $(townResults).html('');
            $(wrapper).find('.js-search-themes').slideDown();
            $(townResults).slideUp();
        }
    }
    document.addEventListener('click', function(event) {
        let item = event.target.closest('.js-set-town-item');
        if (item) {
            let wrapper = item.closest('.js-town-input-wrapper');
            let townInput = wrapper.querySelector('.js-town-input');
            let name = item.dataset.name;
            let id = item.dataset.id;
            townInput.value = name;
            axios.post('/ajax/towns/set', { id })
                .then((response) => {
                    let links = response.data.links;
                    let linksElements = document.querySelectorAll('.js-catalog-link');
                    linksElements.forEach(element => {
                        link = links.find(l=> l.id == element.dataset.id);
                        if (link) {
                            element.href = link.url;
                        }
                    })
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
        $('.js-town-results').slideUp();
    });
}
let townListInput = document.querySelector('.js-town-list-input');
if (townListInput) {
    document.body.classList.add('-is-towns-page');
    $('.navbar-mp ._right').append($('.js-towns-close'));
    townListInput.onkeydown = debounce(searchListTown, 300);
    let townResults = document.querySelector('.js-cities-search');
    let townDefault = document.querySelector('.js-cities-default');
    function searchListTown() {
        let text = townListInput.value;
        if (text.length >= 3) {
            townDefault.classList.add('d-none');
            townResults.classList.remove('d-none');
            axios.post('/ajax/towns', { text })
                .then((response) => {
                    $(townResults).html('');
                    let items = response.data.items || null;

                    if (items.length) {
                        let html = '';
                        items.sort(function (a, b) {
                            return a.name.localeCompare(b.name);
                        });
                        let ch = items[0].name[0];
                        html += `<div class="more-cities__list__card">
                        <h4 class="card-name">${ ch }</h4>`
                        items.forEach(item => {
                            if (item.name[0] != ch) {
                                ch = item.name[0];
                                html += `</div>
                                <div class="more-cities__list__card">
                                <h4 class="card-name">${ ch }</h4>`
                            }
                            html += `<a class="city-name" href="${ item.url }">${ item.text }</a>`;
                        });
                        html += `</div>`;
                        townResults.innerHTML = html;
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        } else {
            townDefault.classList.remove('d-none');
            townResults.classList.add('d-none');
        }
    }
}
//$(".__location_results").on("keyup", function(){  masterLocations.locationSearch($(this)); });
