let sortList = document.querySelector('.js-sort-list');
let calendar = document.querySelector('.js-calendar');
let isCatalog = document.body.classList.contains('-is-catalog-page');
let related = document.querySelector('.js-related');
let defaultSort = null;
let defaultElement =  document.querySelector('[name="mobile_sort"]:checked');
if (defaultElement) {
    defaultSort = defaultElement.value;
}
if (isCatalog) {
    let searchForm = document.getElementById('catalogSearchForm');
    let headerSearchForm = document.querySelector('.js-header-search-wrapper');
    let contentSearchForm = document.querySelector('.js-content-search-wrapper');

    let headerRelated = document.querySelector('.js-header-related-wrapper');
    let contentRelated = document.querySelector('.js-content-related-wrapper');

    if (window.innerWidth <= 768) {
        document.querySelector('.-is-mobile-calendar').after(calendar);
        let mobileInput = document.querySelector('.catalog-show__mobile-top .js-search-input');
        if (mobileInput) {
            mobileInput.disabled = 'disabled';
        }
    }
    window.addEventListener('scroll',  function(event) {
        debounce(setBlocksPositions(), 500);
    });
    let scrolled = false;
    function setBlocksPositions() {
        if (window.innerWidth > 768) {
            if (window.scrollY > 0) {
                document.body.classList.add('-is-scrolled');
            } else {
                document.body.classList.remove('-is-scrolled');
            }
            let scroll = window.scrollY;
            let isScroll = !!scroll;
            if (isScroll) {
                if (!scrolled) {
                    if (searchForm) {
                        headerSearchForm.before(searchForm);
                    }
                    if (related) {
                        headerRelated.before(related);
                        initHeaderRelatedSlider();
                    }
                    scrolled = true;
                }

            } else {
                if (scrolled) {
                    if (searchForm) {
                        contentSearchForm.before(searchForm);
                    }
                    if (related) {
                        contentRelated.before(related);
                        destroyHeaderRelatedSlider();
                    }
                    scrolled = false;
                }

            }
        }
    }
    if (window.innerWidth > 768) {
        initChangeImagesOnHover();
    }


}
function initChangeImagesOnHover() {
    let blocks = document.querySelectorAll('.js-catalog-slider-image');
    blocks.forEach(b => {
        let itms = b.querySelectorAll('.js-catalog-slider-item');
        //let images = b.querySelectorAll('.js-catalog-slider-image');
        itms.forEach(i => {
            i.addEventListener("mouseenter", function( event ) {
                let num = i.dataset.num;
                b.querySelector('.js-catalog-slider-image.-is-show').classList.remove('-is-show');
                b.querySelector(`.js-catalog-slider-image[data-num="${num}"]`).classList.add('-is-show');
                b.querySelector('.js-catalog-slider-item.-is-active').classList.remove('-is-active');
                i.classList.add('-is-active')
            })
        })
    })
}

let skip = 0;
function initHeaderRelatedSlider() {
    displayRelatedElemets()
}
function displayRelatedElemets() {
    let wrapper = related.querySelector('.js-catalog-related-list');
    let wrapperWidth = wrapper.offsetWidth;
    let totalWidth = 0;
    let elements = wrapper.querySelectorAll('a');
    let rightBtn = document.querySelector('.js-catalog-related-right');
    let leftBtn = document.querySelector('.js-catalog-related-left');
    if (skip) {
        leftBtn.classList.remove('d-none');
    } else {
        leftBtn.classList.add('d-none');
    }
    if (elements.length) {
        lastIndex = 0;
        elements.forEach( (e,i) => {
            e.classList.remove('d-none');
            let width = e.offsetWidth += 25;
            if (i >= skip) {
                totalWidth += width;
                if (totalWidth > wrapperWidth) {
                    e.classList.add('d-none');
                } else {
                    lastIndex = i;
                    e.classList.remove('mr-0');
                    totalWidth;
                    if (i+1 >= elements.length) {
                        rightBtn.classList.add('d-none');
                    } else {
                        rightBtn.classList.remove('d-none');
                    }

                }
            } else {
                e.classList.add('d-none');
            }
        })
        elements[lastIndex].classList.add('mr-0')
    }
}
function destroyHeaderRelatedSlider() {
    let wrapper = related.querySelector('.js-catalog-related-list');
    let elements = wrapper.querySelectorAll('a');
    elements.forEach( (e,i) => {
        e.classList.remove('d-none');
        e.classList.remove('mr-0');
        skip = 0;
    })
}
function applyFilter() {
    document.body.classList.add('-is-loading');
    let form = document.querySelector('#catalogSearchForm');
    const formData = new FormData(form);
    let params = {}
    let url = form.action;
    for (var pair of formData.entries()) {
        params[pair[0]] = pair[1];
    }

    axios.get(url,{ params })
        .then(function (response) {
            let data = response.data;
            let wrapper = document.querySelector('.js-catalog-wrapper');
            wrapper.innerHTML = data.html;
            document.body.classList.remove('-is-loading');
            if (data.title) {
                document.querySelectorAll('.js-main-title').forEach(t => t.innerHTML = data.title);
            }
            if (data.description) {
                let w = document.querySelector('.js-catalog-description');
                if (w) {
                    w.innerHTML = data.description;
                }
            }
            if (data.url) {
                history.pushState(params, data.title || '', data.url);
            }
            if (window.innerWidth > 768) {
                initChangeImagesOnHover();
            }
        })
        .catch(function (response) {
            //handle error
            console.log(response);
        });
    masterMap.loadPoints();
}
document.addEventListener('submit', function(event) {
    let form = event.target.closest('.js-search-form');
    if (form) {
        let localSearchElement = document.querySelector('.js-search-form [name="search"]');
        if (localSearchElement) {
            event.preventDefault();
            localSearchElement.value = form.querySelector('[name="search"]').value;
            applyFilter();
            return false;
        }
    }
    let searchForm = event.target.closest('#catalogSearchForm');
    if (searchForm) {
        applyFilter();
        event.preventDefault();
        return false;
    }
})

document.addEventListener('change', function(event) {
    let mobileCheckbox = event.target.closest('.js-subfilter-mobile-item-value');
    if (mobileCheckbox) {
        let wrapper = mobileCheckbox.closest('.js-subfilter-wrapper');
        let mainWrapper = mobileCheckbox.closest('.js-modal-mobile-filters');
        let counter = wrapper.querySelector('.js-subfilter-counter');
        if (counter) {
            let allValuesElements = mainWrapper.querySelectorAll('.js-subfilter-item-value');
            allValuesElements.forEach(e => {
                if (e.name != mobileCheckbox.name) {
                    e.checked = false;
                }
            });
            let valuesElements = wrapper.querySelectorAll('.js-subfilter-item-value');
            let count = 0;
            valuesElements.forEach(e => {
                if (e.checked) count++;
            });
            counter.innerText = count;
            if (count) {
                counter.classList.remove('d-none');
                mainWrapper.classList.add('-is-selected');
            } else {
                mainWrapper.classList.remove('-is-selected');
                counter.classList.add('d-none');
            }
        }
    } else {
        let checkbox = event.target.closest('.js-subfilter-item-value');
        if (checkbox) {
            let diffName =  checkbox.name == 'metro[]' ? 'districts[]' : 'metro[]';
            let elements = document.querySelectorAll(`[name="${diffName}"]`);
            elements.forEach( e => {
                e.checked = false;
            })
            if (checkbox.name == 'metro[]') {
                document.querySelector('[name="disctricts"]').value = '';
            } else {
                document.querySelector('[name="metro"]').value = '';
            }


        }
    }
    let mobileSortCheckbox = event.target.closest('.js-mobile-sort-value');
    if (mobileSortCheckbox) {
        let mainWrapper = mobileSortCheckbox.closest('.js-modal-mobile-filters');
        if (mobileSortCheckbox.value != defaultSort) {
            mainWrapper.classList.add('-is-selected');
        } else {
            mainWrapper.classList.remove('-is-selected');
        }
    }
})
document.addEventListener('click', function(event) {
    let link = event.target.closest('.js-catalog-item-swipe-image');
    if (link) {
        event.preventDefault();
        let wrapper = link.closest('.js-catalog-item');
        let imagesElement = wrapper.querySelector('.js-catalog-item-image');
        let images = JSON.parse(imagesElement.dataset.images);
        let pos = +imagesElement.dataset.pos;
        pos++;
        let image = images[0];
        if (images[pos]) {
            image = images[pos];
            imagesElement.dataset.pos = pos;
        } else {
            imagesElement.dataset.pos = 0;
        }
        imagesElement.style.backgroundImage = `url(${image})`;

    }
    link = event.target.closest('.js-subfilter-open');
    if (link) {
        event.preventDefault();
        document.querySelectorAll('.js-subfilter-list').forEach(e => e.classList.add('d-none'));
        let wrapper = link.closest('.js-subfilter-wrapper');

        let listElement = wrapper.querySelector('.js-subfilter-list');
        listElement.classList.remove('d-none');
    }
    let closeLink = event.target.closest('.js-subfilter-close');
    if (closeLink) {
        closeSubfilter(closeLink);
    }
    let clearLink = event.target.closest('.js-subfilter-search-clear');
    if (clearLink) {
        closeSubfilter(clearLink);
        let wrapper = clearLink.closest('.js-subfilter-wrapper');
        let search = wrapper.querySelector('.js-subfilter-search');

        if (search) {
            search.value = '';
        }
        filterElements(search,wrapper.querySelectorAll('.js-subfilter-item-name'))

    }
    let applyLink = event.target.closest('.js-subfilter-apply');
    if (applyLink) {
        let wrapper = applyLink.closest('.js-subfilter-wrapper');
        let valuesElements = wrapper.querySelectorAll('.js-subfilter-item-value');
        let values = [];
        valuesElements.forEach(e => {
            if (e.checked) values.push(e.value);
        });
        let name = applyLink.dataset.name;
        document.querySelector(`[name="${name}"]`).value = values.join(',');
        applyFilter()
        closeSubfilter(applyLink);
    }
    let applyMobileLink = event.target.closest('.js-subfilter-mobile-apply');
    if (applyMobileLink) {
        let sort = document.querySelector('.js-mobile-sort-value:checked').value;
        defaultSort = sort;
        document.querySelector(`[name="sort"]`).value = sort;
        let wrapper = applyMobileLink.closest('.js-modal-mobile-filters');
        totalCount = 0;
        let filters = wrapper.querySelectorAll('.js-subfilter-wrapper');
        filters.forEach(f => {
            let valuesElements = f.querySelectorAll('.js-subfilter-item-value');
            let values = [];
            valuesElements.forEach(e => {
                if (e.checked) {
                    values.push(e.value);
                    totalCount++;
                };
            });
            let name = f.dataset.key;
            document.querySelector(`[name="${name}"]`).value = values.join(',');
        })
        let conters = document.querySelectorAll('.js-subfilter-total-counter');
        conters.forEach(c => {
            c.innerText = totalCount;
            if (totalCount) {
                c.classList.remove('d-none');
            } else {
                c.classList.add('d-none');
            }
        });
        wrapper.querySelector('.js-close-modal').click();
        document.querySelector('.js-modal-mobile-filters').classList.remove('-is-selected');
        applyFilter()

    }
    let mobileSearchBtn = event.target.closest('.js-apply-mobile-search');
    if (mobileSearchBtn) {
        let searchElement = document.querySelector('.js-modal-mobile-search .js-search-input');
        if (searchElement) {
            let text = searchElement.value;
            let searchForm = document.querySelector('#catalogSearchForm');
            if (searchForm) {
                let searchElement = searchForm.querySelector('.js-search-input');
                searchElement.value = text;
                searchForm.submit();
            }
        }
    }
    let applyDateTime = event.target.closest('.js-date-time-apply');
    if (applyDateTime) {
        calendar.classList.add('d-none');
        applyFilter();
    }
    let switchScreen = event.target.closest('.js-modal-screen-open');
    if (switchScreen) {
        let wrapper = switchScreen.closest('.js-modal-mobile-filters');
        let key = switchScreen.dataset.key;
        let screens = wrapper.querySelectorAll('.js-modal-screen');
        let switched = false;
        screens.forEach(s => {
            if (s.dataset.key == key) {
                switched = true;
                s.classList.remove('d-none');
            } else {
                s.classList.add('d-none');
            }
        })
        if (!switched) {
            wrapper.querySelector('.js-close-modal').click();
        }
    }
    let openSort = event.target.closest('.js-sort-open');
    if (openSort) {
        event.preventDefault();
        sortList.classList.remove('d-none');
    } else {
        if (sortList) {
            sortList.classList.add('d-none');
        }

    }
    let changeSort = event.target.closest('.js-sort-item');
    if (changeSort) {
        event.preventDefault();
        let key = changeSort.dataset.key;
        document.querySelector(`[name="sort"]`).value = key;
        document.querySelector(`.js-sort-name`).innerText = changeSort.innerText;
        document.querySelector(`.js-sort-open`).href = '?sort='+key;
        applyFilter();
    }
    let openCalendar = event.target.closest('.js-open-calendar');

    let calendarWrapper = event.target.closest('.js-calendar');

    if (!calendarWrapper) {
        if (openCalendar) {
            calendar.classList.remove('d-none');
            let height = calendar.offsetHeight - 1;
            document.body.classList.add('-is-calendar-open');
            document.getElementById('app').style.height = height+'px';
        } else if(calendar){
            calendar.classList.add('d-none');
            document.body.classList.remove('-is-calendar-open');
            document.getElementById('app').style.height = 'auto';
        }
    }
    let closeCalendar = event.target.closest('.js-close-calendar');
    if (closeCalendar) {
        calendar.classList.add('d-none');
        document.body.classList.remove('-is-calendar-open');
        document.getElementById('app').style.height = 'auto';
    }
    let showMobileCart = event.target.closest('.js-show-mobile-map-title');
    let catalogResults = document.querySelector('.js-catalog_results');
    if (showMobileCart && catalogResults) {
        if (showMobileCart.classList.contains('-is-show-map')) {
            showMobileCart.classList.remove('-is-show-map');
            document.querySelector('.js-show-mobile-map-title').innerHTML = 'Показать<br> на карте';
            catalogResults.classList.remove('-is-show-mobile-map');
        } else {
            showMobileCart.classList.add('-is-show-map');
            document.querySelector('.js-show-mobile-map-title').innerHTML = 'Скрыть<br> карту';
            catalogResults.classList.add('-is-show-mobile-map');
        }
    }
    link = event.target.closest('.js-open-phone-modal');
    if (link) {
        event.preventDefault();
        let phone = link.dataset.phone;
        let phoneLink = document.querySelector('.js-modal-phone');
        if (phone && phoneLink) {
            phoneLink.href = 'tel:+'+phone;
            phoneLink.innerHTML = '+'+phone;
            $('#callModal').modal('show');
        }
    }
    let rightBtn = event.target.closest('.js-catalog-related-right');
    if (rightBtn) {
        event.preventDefault();
        skip++;
        displayRelatedElemets();
    }
    let leftBtn = event.target.closest('.js-catalog-related-left');
    if (leftBtn) {
        event.preventDefault();
        skip--;
        displayRelatedElemets();
    }
    let townLink = event.target.closest('.js-town-name');
    if (townLink) {
        wrapper = document.querySelector('.js-towns-list');
        wrapper.classList.toggle('d-none');
    }
    let townCloseLink = event.target.closest('.js-town-close');
    if (townCloseLink) {
        wrapper = document.querySelector('.js-towns-list');
        wrapper.classList.add('d-none');
    }
})
function filterElements(searchElement,elements) {
    let text = searchElement.value.toLowerCase();
    if (text) {
        elements.forEach(e => {
            let val = e.innerText.toLowerCase();
            if (val.includes(text)) {
                e.closest('.js-subfilter-item').classList.remove('d-none');
            } else {
                e.closest('.js-subfilter-item').classList.add('d-none');
            }
        })
    } else {
        elements.forEach(e => {
            e.closest('.js-subfilter-item').classList.remove('d-none');
        })
    }
}
/* filter in subfilters */
let subfilterSearchElements = document.querySelectorAll('.js-subfilter-search')
if (subfilterSearchElements.length) {
    subfilterSearchElements.forEach(searchElement => {
        let wrapper = searchElement.closest('.js-subfilter-wrapper')
        let elements = wrapper.querySelectorAll('.js-subfilter-item-name');
        searchElement.addEventListener('keyup', function(event) {
            filterElements(searchElement,elements);

        });
    })
}

function closeSubfilter(element) {
    let wrapper = element.closest('.js-subfilter-wrapper');
    let listElement = wrapper.querySelector('.js-subfilter-list');
    listElement.classList.add('d-none');
}
/* $(".js-open-calendar").click(function(){

    let $dateBlock = $(".js-calendar");
    $dateBlock.show();

    if ($(window).width() < 768) {
        $dateBlock.css({
            'min-height' : $(window).height()+'px'
        });
    }

    setTimeout(function() {
        $("body").one("click", function (e) {

            if (!$(e.target).hasClass("_close_cross") && $(e.target).parents(".js-calendar").length > 0) return;
            $(".js-calendar").hide();
        });

        //if ($("._time_mode a._active").index() !== 1) {
            $("._time_mode a._active").click();
        //}

    }, 250);

    $(".js-calendar ._close_cross").off("click").on("click", function(){
        $(".js-calendar").hide();
    });
}); */
document.addEventListener("DOMContentLoaded", function(event) {
    if ($().datepicker !== undefined) {
        let $datePickerElement = $("#catalog-datepicker");
        if ($datePickerElement.length) {
            $("#catalog-datepicker").datepicker({
                inline: true,
                weekStart: 1,
                language: 'ru',
                startDate: "today"
            }).on('changeDate', function (ev) {
                let _date = $datePickerElement.data('datepicker').getFormattedDate('yyyy-mm-dd');

                $("#catalogSearchForm input[name='date']").val(_date);
                $(".js-calendar ._time_mode").css({ 'display' : 'flex' });

                let _p1 = $datePickerElement.data('datepicker').getFormattedDate('MM d, yyyy')
                $("#date-picker-output").html("<b>"+_p1+"</b>").data("common-date", _p1);
                $("._time_mode a._active").click();
            });
            /**
             * Time
             */
            $("._time_mode a").click(function() {
                $("._time_mode a").removeClass("_active");

                $(this).addClass("_active");

                let $pOut = $("#date-picker-output");
                switch ($(this).data('mode')) {
                    case "any":       $pOut.data("common-date") !== undefined ? $pOut.html("<b>"+$pOut.data("common-date")+", в любое время</b>") : $pOut.html("<i>в любое время</i>") ; break;
                    case "morning":   $pOut.data("common-date") !== undefined ? $pOut.html("<b>"+$pOut.data("common-date")+", утром</b>")         : $pOut.html("<i>утром</i>") ;         break;
                    case "afternoon": $pOut.data("common-date") !== undefined ? $pOut.html("<b>"+$pOut.data("common-date")+", после полудня</b>") : $pOut.html("<i>после полудня</i>") ; break;
                    case "evening":   $pOut.data("common-date") !== undefined ? $pOut.html("<b>"+$pOut.data("common-date")+", вечером</b>")       : $pOut.html("<i>вечером</i>") ;       break;
                }

                $("#catalogSearchForm input[name='time']").val($(this).data('mode'));
            });

            let _default_time = $("#catalogSearchForm input[name='time']").val();

            $("._time_mode a").removeClass("_active");

            let $_default_time_element = $("._time_mode a[data-mode='"+ _default_time +"']");
            if ($_default_time_element.length > 0) {
                $("._time_mode").css({ 'display' : 'flex' });
                $_default_time_element.addClass("_active");
            }
        }

    }
});
