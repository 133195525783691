let searchInputElements = document.querySelectorAll('.js-search-input');
if (searchInputElements.length) {
    searchInputElements.forEach(e => {
        e.onkeydown = debounce(() => {search(e)}, 150);
    })
    function search(searchInput) {
        let text = searchInput.value;
        let service = null;
        let serviceElement = document.querySelector('[name="service"]');
        if (serviceElement) {
            service = serviceElement.value;
        }
        let theme = null;
        let themeElement = document.querySelector('[name="theme"]');
        if (themeElement) {
            theme = themeElement.value;
        }

        let wrapper = searchInput.closest('.js-search-input-wrapper');
        let searchResults = wrapper.querySelector('.js-search_results');
        if (text.length >= 2) {
            $(wrapper).find('.js-search-themes').slideUp();

            axios.post('/ajax/search', { text, service, theme })
                .then((response) => {
                    $(searchResults).html('');
                    let items = response.data.items;
                    items.forEach(item => {
                        if (item.type == 'service') {
                            $(searchResults).append(`<a class="search__results__item" href="${item.url}"><span>${item.text}</span></a>`);
                        } else {
                            $(searchResults).append(`<a class="search__results__item" href="${item.url}" class="district">
                                <span class="search__results__item__name">
                                    ${item.text}
                                    <span class="search__results__item__count">${item.count} специалистов</span>
                                </span>
                                <img class="search__results__item__icon" src="/images/svg/location.svg">
                            </a>`);
                        }
                    })
                    $(searchResults).slideDown();
                })
                .catch(function(error) {
                    console.log(error);
                });
        } else {
            $(searchResults).html('');
            $(wrapper).find('.js-search-themes').slideDown();
        }
    }
    document.addEventListener('click', function(event) {
        let wrapper = event.target.closest('.js-search-input-wrapper');
        if (!wrapper) {
            $('.js-search-themes').slideUp();
        }
        let expandElement = event.target.closest('.js-expand-list');
        if (expandElement) {
            event.preventDefault();
            let wrapper = expandElement.closest('.js-expanded-list');
            if (wrapper.classList.contains('-is-expanded')) {
                wrapper.classList.remove('-is-expanded');
                expandElement.querySelector('.js-expand-list-text').innerText = 'Показать больше';
                wrapper.querySelectorAll('.js-expanded-list-item').forEach(e => e.classList.add('d-none'));
            } else {
                wrapper.classList.add('-is-expanded');
                expandElement.querySelector('.js-expand-list-text').innerText = 'Показать меньше';
                wrapper.querySelectorAll('.js-expanded-list-item').forEach(e => e.classList.remove('d-none'));
            }
            return;
        }

        let input = event.target.closest('.js-search-input');
        if (input) {
            if (window.innerWidth <= 768) {
                openMobilePopup('.js-modal-mobile-search');
                let mobileInput = document.querySelector('.js-modal-mobile-search .js-search-input');
                if (mobileInput) {
                    mobileInput.focus();
                    if (!input.value) {
                        let wrapper = mobileInput.closest('.js-search-input-wrapper');
                        $(wrapper).find('.js-search-themes').slideDown();
                    }
                };
            } else {
                if (!input.value) {
                    let wrapper = input.closest('.js-search-input-wrapper');
                    $(wrapper).find('.js-search-themes').slideToggle();
                } else {
                    $('.js-search-themes').slideUp();
                }
            }
        }
        let link = event.target.closest('.js-open-mobile-popup');
        if (link && window.innerWidth <= 768) {
            event.preventDefault();
            openMobilePopup('.js-modal-mobile-search');
            let mobileInput = document.querySelector('.js-modal-mobile-search .js-town-input');
            if (mobileInput) mobileInput.focus();
            return false;
        }
        let closePopup = event.target.closest('.js-close-modal');
        if (closePopup) {
            closeMobilePopup(closePopup)
        }
        let openFilters = event.target.closest('.js-open-filters');
        if (openFilters) {
            openMobilePopup('.js-modal-mobile-filters');
        }

    });
}
function openMobilePopup(q) {
    document.body.classList.add('-is-mobile-popup');
    document.querySelector(q).classList.remove('d-none');

}
function closeMobilePopup(e) {
    document.body.classList.remove('-is-mobile-popup');
    let popup = e.closest('.js-modal-popup');
    popup.classList.add('d-none');
    let input = popup.querySelector('.js-search-input');
    if (input) {
        input.value = '';
    }
}
