<template>
    <div class="chat2_wrap">
        <div class="chat2">
            <div class="_contacts">
                <div class="scroll-tiny-without-bg">
                    <p v-if="!loaded" style="font-size: 11px;padding:15px;text-align:center;">Loading...</p>

                    <div class="_items">
                        <div class="_item" v-for="contact in contactsList" :data-chat-id="contact.chat_id" @click="clickContact" v-bind:class="{ not_read: contact.not_read, 'active' : chat_id == contact.chat_id }">
                            <div class="_avatar">
                                <div class="_image" v-bind:style="{ backgroundImage: 'url(' + contact.avatar + ')' }"></div>
                            </div>
                            <div class="_info">
                                <div class="_login">
                                    {{ contact.login }}
                                    <!--<span v-if="contact.not_read" class="not_read_count">({{ contact.not_read }})</span>-->
                                </div>
                                <div class="_date">{{ contact.last_date }}</div>
                            </div>
                            <div class="_state"><i></i></div>
                        </div>
                    </div>

                    <p v-if="loaded && !contactsList.length" style="font-size: 11px;padding:15px;text-align:center;">No contacts.</p>
                </div>
            </div>
            <div class="_chat">
                <div class="chat2_box">

                    <div class="_mobile_back">
                        <div class="_back"><img src="/images/chat2/back.svg" @click="mobileBack" /></div>
                        <div class="_ava"><span></span></div>
                        <div class="_name"></div>
                    </div>

                    <div class="chat_box_wrapper scroll-tiny-without-bg">
                        <div class="">
                            <div v-for="item in messages">
                                <div class="_c" v-bind:class="{ _my: ( item.user_id === myId ) }">
                                    <div class="_ava"><span :style="{ backgroundImage: `url('${item.avatar}')` }" onclick="location.href=this.getAttribute('href')" :href="'/to_user_business/' +item.user_id+'.html'"></span></div>
                                    <div class="_message">
                                        <p v-html="item.msg"></p>
                                        <div class="_date" v-if="item.blocked == undefined || !item.blocked">{{item.date}}</div>
                                    </div>
                                </div>

                            </div>
                            <p v-if="msg_loading" style="font-size: 11px;padding:15px;text-align:center;">Loading...</p>
                        </div>
                    </div>

                    <div class="chat2-write-area" v-if="showSubmitForm && !blockedWrite">
                        <div class="_textedit" ref="textEdit" contenteditable="true" @keydown="inputKeyDown" @keyup="inputKeyUp" @focus="inputFocus" @blur="inputBlur">Отправьте сообщение...</div>
                        <span class="_btn" v-on:click="sendMessage" v-html="sendButtonHtml"></span>
                    </div>

                    <div class="chat-restrictions-block" v-if="blockedWrite">
                        <div class="no-premium">
                            <div>У вас непрочитанное сообщение от клиента!</div>
                            <div>
                                Для прочтения необходимо приобрести подписку.
                            </div>
                            <div>Это сообщение возможно может быть очень важным для вас и вовремя отвеченные вопросы по бронированию или услугам мастера, могут принести в вашу копилку еще одного клиента.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="settings-subscription-buy" style="margin-top: 15px; text-align: left;" v-if="blockedWrite">
            <div class="_info">
                <h5>Купить подписку</h5>
                <p>Аккаунты оплатившие подписку получают:</p>
                <ul>
                    <li><i class="fas fa-check"></i> Неограниченный доступ ко всему функционалу системы</li>
                    <li><i class="fas fa-check"></i> Возможность принимать заказы внутри системы</li>
                    <li><i class="fas fa-check"></i> Увеличение клиентской базы</li>
                </ul>
            </div>
            <div class="_buy_block">
                <div class="_premium_cost">
                    <div>
                        Итого:
                    </div>
                    <div>
                        <b>699 руб.</b> / месяц
                    </div>
                </div>
                <a href="/to_pay" class="btn btn-style">Перейти к оплате <i class="fas fa-arrow-right"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            'writeToId' : {
                type: Number
            },
            'writeToIdFromRequest' : {
                type: Boolean
            },
            'myAvatar' : {
                type: String
            },
            'myId' : {
                type: Number
            },
            'blockedWrite' : {
                type: Boolean
            },
            'havePremium' : {
                type: Boolean
            }
        },
        data() {
            return {
                contactsList: [],
                messages: [],
                txtMsg: "",
                msg_loading: false,
                loaded: false,
                sendButtonHtml: '<svg width="39" height="37" viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M36.0739 15.0981L6.57407 0.823216C4.63091 -0.118585 2.29225 0.693168 1.35037 2.63633C0.870119 3.62716 0.829951 4.77453 1.23972 5.79659L5.84815 17.3137H38.051C37.7119 16.3421 37.0007 15.5451 36.0739 15.0981Z" fill="#6C6C6C"/> <path d="M5.84817 19.9202L1.23973 31.4399C0.440047 33.4511 1.42217 35.7299 3.43338 36.5296C3.88874 36.7107 4.37411 36.8048 4.86414 36.8069C5.45627 36.8069 6.04076 36.6733 6.57401 36.4159L36.0738 22.1358C37.0007 21.6887 37.7119 20.8917 38.0509 19.9202H5.84817Z" fill="#6C6C6C"/> </svg>',
                sending: false,
                chat_id: false,
                currentChannel: null,
                login: null,
                user_id: null,
                showSubmitForm: false,
            }
        },

        mounted()
        {
            axios.post('/messages/ajax_init_chat', { writeToId : this.writeToId })
                    .then((response) => {
                        if (response.status === 200)
                        {
                            this.loaded = true;

                            if (response.data.login !== undefined && response.data.login !== null) {
                                this.login = response.data.login;
                            }
                            if (response.data.user_id !== undefined && response.data.user_id !== null) {
                                this.user_id = response.data.user_id;

                                window.Echo.private('chat_bar.' + this.user_id)
                                            .listen('ChatBarMessage', (query) =>
                                            {
                                                this.updateContactsList();
                                            });
                            }

                            if (response.data.last_chat_id !== undefined && response.data.last_chat_id !== null)
                            {
                                if (response.data.have_blocked_messages !== undefined && response.data.have_blocked_messages === true) {
                                    this.blockedWrite = true;
                                }/* else {
                                    this.blockedWrite = false;
                                }*/

                                this.messages     = response.data.last_chat_messages;

                                this.changeChannel(response.data.last_chat_id);
                                this.scrollBottom();

                                setTimeout(() => {
                                    this.updateContactsList();

                                    this.adaptDesign();

                                    this.scrollBottom();
                                }, 1000);
                            }

                            if (response.data.contactsList !== undefined && response.data.contactsList !== null)
                            {
                                this.contactsList = response.data.contactsList;

                                // Set first active
                                setTimeout(() => {
                                    let firstElem = document.querySelector('._contacts ._items ._item:first-child');
                                    if (firstElem !== null) {
                                        document.querySelector('._contacts ._items ._item:first-child').classList.add('active');
                                    }

                                    this.adaptDesign();
                                }, 250);
                            }

                            this.adaptDesign();

                            // Open on mobile "writeToId"
                            if (this.writeToId !== null && this.writeToIdFromRequest === true) {
                                if (response.data.last_chat_id !== undefined && response.data.last_chat_id !== null) {
                                    if (window.innerWidth < 1000)
                                    {
                                        setTimeout(() => {
                                            let $activeContact = document.querySelector('._contacts div[data-chat-id="'+response.data.last_chat_id+'"]');
                                            if ($activeContact !== undefined && $activeContact !== null) {
                                                $activeContact.click();
                                            }
                                        }, 250);
                                    }
                                }
                            }
                        }
                    });

            this.scrollBottom();
        },

        methods : {

            mobileBack(e)
            {
                document.querySelector('.chat2').classList.remove('__gone');
                document.querySelector('.messages2-page .mobile-header').style.display = 'block';
                document.querySelector('.navbar-fluid').style.display = 'block';
                document.querySelector('main').style.paddingTop = document.querySelector('.navbar-fluid').offsetHeight +'px';
            },

            adaptDesign()
            {
                let $writeArea = document.querySelector('.chat2-write-area');
                let $mobileBack = document.querySelector('._mobile_back');

                if (window.innerWidth < 1000)
                {
                    // Chat Height
                    let chatHeight = window.innerHeight - ($mobileBack !== null ? $mobileBack.offsetHeight : 0 ) - ( $writeArea !== null ? $writeArea.offsetHeight : 0 ) - 3;
                    document.querySelector('.chat2_box .chat_box_wrapper').style.height = chatHeight + 'px';
                } else {
                    /*
                     * Desktop
                     */

                    let $preBlock = document.querySelector('.messages2-pre-block');

                    // Chat Height
                    let offset = ($preBlock !== null ? $preBlock.getBoundingClientRect() : { y : 0 } );
                    let chatHeight = window.innerHeight - offset.y - 3;
                    document.querySelector('.chat2_box .chat_box_wrapper').style.height = (chatHeight - ($mobileBack !== null ? $mobileBack.offsetHeight : 0 ) - ( $writeArea !== null ? $writeArea.offsetHeight : 0 )) + 'px';
                    document.querySelector('.chat2 ._contacts > div').style.height = chatHeight + 'px';
                }
            },

            /**
             * openChannel
             */
            clickContact(e)
            {
                let new_chat_id = e.currentTarget.getAttribute('data-chat-id');

                // Active class
                let $divs = document.querySelector('._contacts div.active');
                if ($divs !== null) {
                    document.querySelector('._contacts div.active').classList.remove('active');
                }
                document.querySelector('._contacts div[data-chat-id="'+new_chat_id+'"]').classList.add('active');

                if (window.innerWidth < 1000) {
                    document.querySelector('.chat2').classList.add('__gone');
                    document.querySelector('.messages2-page .mobile-header').style.display = 'none';
                    document.querySelector('.navbar-fluid').style.display = 'none';
                    document.querySelector('main').style.paddingTop = '0';
                    document.querySelector('main').classList.add('noPadding');

                    setTimeout(function(){ document.querySelector('main').style.paddingTop = '0'; }, 250);
                    setTimeout(function(){ document.querySelector('main').style.paddingTop = '0'; }, 750);
                    setTimeout(function(){ document.querySelector('main').style.paddingTop = '0'; }, 1000);
                }
                this.adaptDesign();

                this.changeChannel(new_chat_id);

                this.messages = [];

                this.msg_loading = true;

                axios.post('/messages/ajax_get_messages', {
                    'chat_id' : this.chat_id
                })
                .then((response) => {
                    this.msg_loading = false;

                    if (response.status === 200)
                    {
                        if (response.data.have_blocked_messages !== undefined && response.data.have_blocked_messages === true) {
                            this.blockedWrite = true;
                        }/* else {
                            this.blockedWrite = false;
                        }*/

                        if (response.data.messages !== undefined && response.data.messages !== null)
                        {
                            this.messages     = response.data.messages;
                            this.scrollBottom();
                        }

                        setTimeout(() => {
                            this.updateContactsList();
                            this.scrollBottom();
                        }, 1000);
                    }
                });
            },
            activeClass()
            {
                if (this.chat_id == parseInt(e.currentTarget.getAttribute('data-chat-id'))) {
                    return 'active';
                } else {
                    return '';
                }
            },
            /**
             * openChannel
             */
            changeChannel(new_chat_id)
            {
                if (this.chat_id != null) {
                    window.Echo.leave('chat.' + this.chat_id);
                }

                this.chat_id = new_chat_id;
                this.showSubmitForm = true;

                // mobile_back
                let $activeContact = document.querySelector('._contacts div[data-chat-id="' + new_chat_id + '"]');
                if ($activeContact !== null) {
                    document.querySelector('._mobile_back ._name').textContent = $activeContact.querySelector('._login').textContent;
                    document.querySelector('._mobile_back ._ava span').style.backgroundImage = $activeContact.querySelector('._image').style.backgroundImage;
                }

                window.Echo.private('chat.' + this.chat_id)
                        .listen('ChatMessage', (query) =>
                        {
                            // check on already exists
                            for (let i = 0; i < this.messages.length; i++){
                                if (parseInt(query.id) === parseInt(this.messages[i].id)) {
                                    return;
                                }
                            }

                            // Spec Mode | May be temp
                            if (!this.havePremium) {
                                location.reload();
                            }
                            else
                            {
                                this.messages.push(query);
                                this.$forceUpdate();
                                this.scrollBottom();

                                if (query.data !== undefined && query.data.have_blocked_messages !== undefined && query.data.have_blocked_messages === true) {
                                    this.blockedWrite = true;
                                }
                            }
                        });
            },
            /**
             * scrollBottom
             */
            scrollBottom() {
                setTimeout(()=>{
                    let container = this.$el.querySelector(".chat_box_wrapper");
                    container.scrollTop = container.scrollHeight;
                }, 250);
            },
            /**
             * updateContactsList
             */
            updateContactsList()
            {
                axios
                    .post('/messages/get_contacts_list', {
                        'writeToId' : this.writeToId
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            this.contactsList = response.data.contactsList;
                            this.$forceUpdate();

                            if (response.data !== undefined && response.data.have_blocked_messages !== undefined && response.data.have_blocked_messages === true) {
                                this.blockedWrite = true;
                            }
                        }
                    });
            },
            /**
             * sendMessage
             */
            sendMessage()
            {
                let $textEdit = document.querySelector(".chat2_box .chat2-write-area ._textedit");

                if (this.sending) {
                    return;
                }

                if (this.chat_id === null || $textEdit.textContent.length === 0) {
                    return;
                }

                if ($textEdit.textContent === "Отправьте сообщение...") {
                    return;
                }

                this.sending = true;

                axios
                    .post('/messages/send_message', {
                        msg     : $textEdit.textContent,
                        chat_id : this.chat_id
                    })
                    .then((response) => {

                        this.sending = false;
                        //this.sendButtonHtml = '<svg width="39" height="37" viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M36.0739 15.0981L6.57407 0.823216C4.63091 -0.118585 2.29225 0.693168 1.35037 2.63633C0.870119 3.62716 0.829951 4.77453 1.23972 5.79659L5.84815 17.3137H38.051C37.7119 16.3421 37.0007 15.5451 36.0739 15.0981Z" fill="#6C6C6C"/> <path d="M5.84817 19.9202L1.23973 31.4399C0.440047 33.4511 1.42217 35.7299 3.43338 36.5296C3.88874 36.7107 4.37411 36.8048 4.86414 36.8069C5.45627 36.8069 6.04076 36.6733 6.57401 36.4159L36.0738 22.1358C37.0007 21.6887 37.7119 20.8917 38.0509 19.9202H5.84817Z" fill="#6C6C6C"/> </svg>';

                        if (response.status === 200) {


                            if (response.data !== undefined && response.data.have_blocked_messages !== undefined && response.data.have_blocked_messages === true) {
                                this.blockedWrite = true;
                            }
                        }
                    });

                /*
                 * Сообщение добавляем в любом случае
                 */
                let obj = {
                    date    : 'только что',
                    login   : this.login,
                    user_id : this.user_id,
                    msg     : $textEdit.textContent,
                    avatar  : this.myAvatar
                };

                this.messages.push(obj);

                $textEdit.textContent = "Отправьте сообщение...";
                this.scrollBottom();

                // Меняем цвет кнопки
                let $sendButton = document.querySelector(".chat2_box .chat2-write-area ._btn svg");
                if ($sendButton !== null) {
                    $sendButton.classList.remove('__blue');
                }
            },
            inputKeyUp(e)
            {
                this.highlightButton();
            },
            inputKeyDown(e)
            {
                let $textEdit = document.querySelector(".chat2_box .chat2-write-area ._textedit");

                // CTRL-SHIFT+ENTER
                if (e.keyCode === 13 && ( e.ctrlKey || e.shiftKey )) {
                    $textEdit.textContent = $textEdit.textContent + "\n";
                }
                // ENTER WITHOUT CTRL
                else if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
                    e.preventDefault();
                    this.sendMessage();
                }
            },
            inputFocus(e) {
                if (e.target.textContent === "Отправьте сообщение...") {
                    e.target.textContent = '';
                }
            },
            inputBlur(e) {
                // console.log("inputBlur");

                if (e.target.textContent.length === 0) {
                    e.target.textContent = 'Отправьте сообщение...';
                }

                this.highlightButton();
            },
            highlightButton()
            {
                let $textEdit   = document.querySelector(".chat2_box .chat2-write-area ._textedit");
                let $sendButton = document.querySelector(".chat2_box .chat2-write-area ._btn svg");
                if ($sendButton !== null && $textEdit !== null) {
                    if ($textEdit.textContent.length > 0 && $textEdit.textContent !== 'Отправьте сообщение...') {
                        $sendButton.classList.add('__blue');
                    } else {
                        $sendButton.classList.remove('__blue');
                    }
                }
            }
        }
    }
</script>
