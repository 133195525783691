import Swiper from 'swiper/swiper-bundle';
document.addEventListener("DOMContentLoaded", function(event) {
    let sliderElement = document.querySelector('.js-main-themes-slider');
    if (sliderElement) {
        let swiperMainThemes = new Swiper(sliderElement, {
            loop: true,
            autoplay: false,
            slidesPerView: 6,
            disableOnInteraction: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            on: {
                init: function () {
                    let themesElements = document.querySelectorAll('.js-slide-theme');
                    themesElements.forEach(t => {
                        t.addEventListener('mouseenter', function(event) {
                            themesElements.forEach(t => {t.classList.remove('-is-active')});
                            t.classList.add('-is-active');
                            showServicesByTheme();
                        });
                    })

                },
            },
        });



        let servicesLinks = document.querySelectorAll('.js-slider-service-link');
        let servicesPrices = document.querySelectorAll('.js-slider-service-price');
        let servicesElements = document.querySelectorAll('.js-slider-service-element');
        let townsElements = document.querySelectorAll('.js-slider-town-element');
        let townElement = document.querySelector('.js-slider-active-town');
        function setServicesPrices() {

            let servicesPrices = document.querySelectorAll('.js-slider-service-price');
            servicesPrices.forEach(p => {
                let town_id = townElement.dataset.id;
                if (!p.parentElement.classList.contains('d-none') && !+p.dataset.price) {
                    let id = p.dataset.id;
                    axios.get('/ajax/service_price', { params: { id, town_id }})
                    .then((response) => {
                        if (!!response.data.price) {
                            p.dataset.price = response.data.price;
                            p.innerHTML = `≈ ${response.data.price.toLocaleString()} ₽`;
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
                }
            })
        }
        function setActiveTown() {
            let id = townElement.dataset.id;
            townsElements.forEach(t => {
                if (t.dataset.id == id) {
                    t.classList.add('-is-active');
                    let townSlug = t.dataset.slug;
                    servicesLinks.forEach(l => {
                        l.href = `${townSlug}/${l.dataset.slug}`
                    })
                } else {
                    t.classList.remove('-is-active');
                }
            });
            servicesPrices.forEach(p => {
                p.innerHTML = '';
                p.dataset.price = '';
            })
            setServicesPrices();
        }
        setActiveTown();
        function showServicesByTheme() {
            let sliderActiveThemeElement = document.querySelector('.js-slide-theme.-is-active');
            if (sliderActiveThemeElement) {
                let theme = sliderActiveThemeElement.dataset.theme;

                servicesElements.forEach(e => {
                    if (e.dataset.section == theme) {
                        e.classList.add('d-inline-flex');
                        e.classList.remove('d-none');
                    } else {
                        e.classList.add('d-none');
                        e.classList.remove('d-inline-flex');
                    }
                })
                setServicesPrices();
            }
        }
        showServicesByTheme();
        document.addEventListener('click', function(event) {
            let tElement = event.target.closest('.js-slider-town-element');
            if (tElement) {
                let text = tElement.text;
                townElement.innerHTML = text;
                townElement.dataset.id = tElement.dataset.id;
                setActiveTown();
            }

        });

    }
});
